import React, { useEffect, useState } from 'react';
import './Raw.css';
import { useTheme } from '../theme/ThemeContext';
import DataTable from 'react-data-table-component';
// import { data } from 'react-router-dom';
import dumimg from './raw-img/360_F_96450662_SPjrCNYmHizwNptyLBv7kRdjplt5hRIz.jpg'

const Raw = () => {

  const backendurl = window.location.hostname === 'localhost'
    ? ''
    : process.env.REACT_APP_BACKEND_URL;

  const token = localStorage.getItem('authToken')
  console.log("token :", token)


  const { theme } = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [rawMaterials, setRawMaterials] = useState([]); // State to store raw materials
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [editData, setEditData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showfeaturepopup, setShowfeaturepopup] = useState(false)
  const [deletefeaturepopup, setDeleteFeaturePopup] = useState(false)
  const [addfeaturepopup, setAddFeaturePopup] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [historyData, setHistoryData] = useState([]);

  const [features, setFeatures] = useState([]);
  const [addPopup, setAddPopup] = useState(false);
  const [showHistoryPopup, setShowHistoryPopup] = useState(false)
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [devices, setDevices] = useState([]);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [stockpopup, setStockPopup] = useState(false)
  const [newRawMaterial, setNewRawMaterial] = useState({
    component: "",
    category: "",
    stock_quantity: "",
    reorder_level: "",
    package: "",
    unit_price_in_rupees: "",
    unit_price_in_dollars: "",
    reference_no: "",
    tax: "",
    shipping_charge: "",
    // raw_material_features: [], // Array of features
    imageFile: null,
  });
  const [selectedStock, setSelectedStock] = useState(null);
  const [updateData, setUpdateData] = useState({
    unit_price_in_rupees: '',
    stock_quantity: '',
    tax: '',
    shipping_charge: ''
  });




  const handleHistory = (row) => {

    setShowHistoryPopup(true)
    fetchHistory(row.id)
  }


  const fetchHistory = async (id) => {
    try {
      const response = await fetch(`${backendurl}/dashboard/api/raw/stock/history/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        if (response.status === 404) {
          setHistoryData([]); // Clear history data if not found
          throw new Error('No history found for this item');
        }
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch history');
      }
  
      const data = await response.json();
      setHistoryData(data.history);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  

  // useEffect(() => {
  //   if (showHistoryPopup) {
  //     fetchHistory();
  //   }
  // }, [showHistoryPopup]);







  const handleStock = (stock) => {
    setSelectedStock(stock);
    setStockPopup(true);
    setUpdateData({
      unit_price_in_rupees: stock.unit_price_in_rupees,
      stock_quantity: stock.stock_quantity,
      tax: stock.tax,
      shipping_charge: stock.shipping_cost
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateStock = async () => {
    try {
      const response = await fetch(`${backendurl}/dashboard/api/raw/stock/update/${selectedStock.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updateData),
      });

      const result = await response.json();
      if (response.ok) {
        alert('Stock updated successfully');
        setStockPopup(false);
        // Refresh stock data
        setRawMaterials((prev) =>
          prev.map((item) =>
            item.id === selectedStock.id
              ? { ...item, stock_quantity: item.stock_quantity + parseInt(updateData.stock_quantity) }
              : item
          )
        );
      } else {
        alert(`Error: ${result.message}`);
      }
    } catch (error) {
      console.error('Error updating stock:', error);
    }
  };



  const [newFeatures, setNewFeatures] = useState([{ feature: "", value: "" }]);
  const [selectedMaterials, setSelectedMaterials] = useState([]); // Store selected materials
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleFeatureChange = (index, key, value) => {
    const updatedFeatures = [...newFeatures];
    updatedFeatures[index][key] = value;
    setNewFeatures(updatedFeatures);
  };

  const addFeatureField = () => {
    setNewFeatures([...newFeatures, { feature: "", value: "" }]);
  };

  const handleMaterialSelect = (id, name) => {
    setSelectedMaterials((prev) => {
      const isAlreadySelected = prev.some((material) => material.id === id);

      if (isAlreadySelected) {
        return prev.filter((material) => material.id !== id); // Remove if already selected
      } else {
        return [...prev, { id, name }]; // Add new selection
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedMaterials.length === 0) {
      alert("Select at least one material.");
      return;
    }

    try {
      for (const material of selectedMaterials) {
        const response = await fetch(
          `${backendurl}/dashboard/api/raw_materials/add_features/${material.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ raw_material_features: newFeatures }),
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to add features for ${material.name}`);
        }
      }

      // alert("Features added successfully!");
      setAddFeaturePopup(false); // Close the popup after submission
      setSelectedMaterials([]); // Reset selection
      fetchRawMaterials()
    } catch (error) {
      console.error("Error adding features:", error);
      alert("Error adding features. Please try again.");
    }
  };






  const handleAdd = () => {
    setAddPopup(true)
  }

  const handleEdit = (row) => {
    setEditData(row);
    setShowPopup(true);
  };

  const handelFeaturepopup = (row) => {
    setSelectedFeature(row)
    setShowfeaturepopup(true)
  }

  const handleDeleteFeature = (row) => {
    setSelectedFeature(row)
    setDeleteFeaturePopup(true)
  }
  const handleAddFeature = () => {
    setAddFeaturePopup(true)
  }

  // Fetch raw materials from the backend

  const fetchRawMaterials = async () => {
    try {
      const response = await fetch(`${backendurl}/dashboard/api/raw_materials`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch raw materials");
      }

      const data = await response.json();
      setRawMaterials(data.raw_materials || []);
      setFilteredMaterials(data.raw_materials || []);
      // console.log("raw data",data);


      // Extract unique raw material feature names dynamically
      const featureColumns = new Set();
      data.raw_materials.forEach((item) => {
        item.raw_material_features?.forEach((feature) => {
          featureColumns.add(feature.raw_material_feature);
        });
      });

      setDynamicColumns(Array.from(featureColumns)); // Convert Set to Array
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRawMaterials();
  }, []);


  // useEffect(() => {
  //   fetchFeatures();
  // }, []);

  // const fetchFeatures = async () => {
  //   try {
  //     const response = await fetch(`${backendurl}/dashboard/api/model/features`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });
  //     const data = await response.json();
  //     setFeatures(data);
  //     console.log("fetch feature",data)
  //   } catch (error) {
  //     console.error('Error fetching features:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleEditFeature = (feature) => {
    setSelectedFeature(feature);
    setShowfeaturepopup(true);
  };




  useEffect(() => {
    const lowerCaseTerm = searchTerm.toLowerCase();
    const filtered = rawMaterials.filter(
      (material) =>
        material.component?.toLowerCase().includes(lowerCaseTerm) ||
        material.category?.toLowerCase().includes(lowerCaseTerm) ||
        material.package?.toLowerCase().includes(lowerCaseTerm)
    );
    setFilteredMaterials(filtered);
  }, [searchTerm, rawMaterials]);

  const handleDeleteClick = (row) => {
    setDeleteData(row);
    setDeletePopup(true);
  };
  const handleDeleteConfirm = async () => {
    try {
      await fetch(`${backendurl}/dashboard/api/raw_materials/delete/${deleteData.id}`, {
        method: "DELETE",
        headers: {
          'Authorization': ` Bearer ${token}`,
          // Dynamic origin based on the frontend domain
        },
        withCredentials: true,
      });
      setDevices(devices.filter((device) => device.id !== deleteData.id));
      setDeletePopup(false);
      fetchRawMaterials()
    } catch (error) {
      console.error("Error deleting device:", error);
    }
  };


  const handleCloseDeletePopup = () => {
    setDeletePopup(false);
    setDeleteData(null);
  };

  const handleAddSave = async () => {
    const formData = new FormData();
    formData.append("Component", newRawMaterial.component);
    formData.append("category", newRawMaterial.category);
    formData.append("stock_quantity", newRawMaterial.stock_quantity);
    formData.append("reorder_level", newRawMaterial.reorder_level);
    formData.append("package", newRawMaterial.package);
    formData.append("unit_price_in_rupees", newRawMaterial.unit_price_in_rupees);
    formData.append("unit_price_in_dollars", newRawMaterial.unit_price_in_dollars);
    formData.append("reference_no", newRawMaterial.reference_no);
    formData.append("tax", newRawMaterial.tax);
    formData.append("shipping_charge", newRawMaterial.shipping_charge);

    // if (newRawMaterial.raw_material_features.length > 0) {
    //     newRawMaterial.raw_material_features.forEach((feature, index) => {
    //         formData.append(`raw_material_features[${index}][feature]`, feature.feature);
    //         formData.append(`raw_material_features[${index}][value]`, feature.value);
    //     });
    // }

    if (newRawMaterial.imageFile) {
      formData.append("image", newRawMaterial.imageFile);
    }

    try {
      const response = await fetch(`${backendurl}/dashboard/api/raw_materials/create`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to add raw material");
      }

      await fetchRawMaterials();
      setAddPopup(false);
    } catch (err) {
      console.error("Error adding raw material:", err);
      alert(`Failed to save changes: ${err.message}`);
    }
  };

  const deleteFeature = async (feature) => {
    console.log("delete features:", feature.feature_id);
    const feature_id = feature.feature_id;
    console.log("deleeeeeteeeee", feature_id);

    try {
      await fetch(`${backendurl}/dashboard/api/raw_materials/delete_feature/${feature_id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      fetchRawMaterials()
      setDeleteFeaturePopup(false)

    } catch (error) {
      console.error('Error deleting feature:', error);
    }
  };


  const updateFeature = async (feature) => {
    console.log("Updating features:", feature.raw_material_features);

    try {
      await Promise.all(
        feature.raw_material_features.map(async (f) => {
          const feature_id = f.feature_id;

          const response = await fetch(`${backendurl}/dashboard/api/raw_materials/update_feature/${feature_id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              raw_material_value: f.raw_material_value || "", // Send empty values instead of removing input
            }),
          });

          if (!response.ok) {
            throw new Error(`Failed to update feature ID: ${feature_id}`);
          }
        })
      );

      setShowfeaturepopup(false);
      fetchRawMaterials(); // Refresh the list after update
    } catch (error) {
      console.error("Error updating features:", error);
    }
  };





  const handleSave = async () => {
    if (!editData) {
      alert("No data to save.");
      return;
    }

    const formData = new FormData();
    formData.append("component", editData.component || "");
    formData.append("category", editData.category || "");
    formData.append("stock_quantity", editData.stock_quantity || "");
    formData.append("reorder_level", editData.reorder_level || "");
    formData.append("package", editData.package || "");
    formData.append("unit_price_in_rupees", editData.unit_price_in_rupees || "");
    formData.append("unit_price_in_dollars", editData.unit_price_in_dollars || "");

    if (editData.imageFile) {
      formData.append("image", editData.imageFile);
    }

    try {
      const response = await fetch(`${backendurl}/dashboard/api/raw_materials/update/${editData.id}`, {
        method: "PUT",
        headers: {
          'Authorization': ` Bearer ${token}`,
          // Dynamic origin based on the frontend domain
        },
        withCredentials: true,
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to update raw material");
      }

      const updatedMaterial = await response.json();

      // Update the state to reflect changes
      setRawMaterials((prevMaterials) =>
        prevMaterials.map((material) =>
          material.id === editData.id ? { ...material, ...updatedMaterial } : material
        )
      );

      setShowPopup(false); // Close the popup
      fetchRawMaterials()
    } catch (err) {
      console.error("Error updating raw material:", err);
      alert(`Failed to save changes: ${err.message}`);
    }
  };




  const baseColumns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "Image",
      selector: (row) => row.image || "N/A",
      sortable: true,
      cell: (row) =>
        row.image ? (
          <img
            src={row.image.startsWith("http") ? row.image : `http://localhost:5000${row.image}`}
            alt="Profile"
            style={{ width: 40, height: 40, borderRadius: "50%" }}
            onError={(e) => {
              e.target.src = "/path/to/default-image.jpg"; // Fallback image
            }}
          />
        ) : (
          <div
            style={{
              width: 40,
              height: 40,
              backgroundColor: "#ccc",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ fontSize: "0.8em", color: "#555" }}>N/A</span>
          </div>
        ),
    },
    { name: "Component", selector: (row) => row.component || "-", sortable: true },
    { name: "Category", selector: (row) => row.category || "-", sortable: true },
    { name: "Stock", selector: (row) => row.stock_quantity || "-", sortable: true },
    { name: "Reorder", selector: (row) => row.reorder_level || "-", sortable: true },
    { name: "Package", selector: (row) => row.package || "-", sortable: true },
    { name: "IND", selector: (row) => row.unit_price_in_rupees || "-", sortable: true },
    { name: "USD", selector: (row) => row.unit_price_in_dollars || "-", sortable: true },
    { name: "Tax", selector: (row) => row.tax || "-", sortable: true },
    { name: "Shipping Cost", selector: (row) => row.shipping_cost || "-", sortable: true },
    { name: "Total Price", selector: (row) => row.total_price || "-", sortable: true },
  ];

  // Generate dynamic feature columns
  const featureColumns = dynamicColumns.map((feature) => ({
    name: feature,
    selector: (row) => {
      const featureData = row.raw_material_features?.find(
        (item) => item.raw_material_feature === feature
      );
      return featureData ? featureData.raw_material_value : "-";
    },
    sortable: true,
  }));

  // Add action column
  const actionColumn = {
    name: "Action",
    cell: (row) => (
      <div className="device-action-btn" >
        <button className="action-button" onClick={() => handleEdit(row)}>
          <i className="fa-solid fa-pen-to-square"></i>
        </button>
        <button className="action-button">
          <i className="fa-solid fa-trash-can" onClick={() => handleDeleteClick(row)}></i>
        </button>
        {/* <button className="action-button"  >
        <i class="fa-solid fa-clock-rotate-left" onClick={handleHistory}></i>
        </button> */}
        {/* <button className="action-button"  >
        <i class="fa-solid fa-pen-nib"></i>
        </button> */}
      </div>
    ),
  };
  const stockColumn = {
    name: "Stock Edit",
    cell: (row) => (
      <div className="device-action-btn" >
        {console.log("jhv", row)}

        <button className="action-button"  >
          <i class="fa-solid fa-clock-rotate-left" onClick={() => handleHistory(row)}></i>
        </button>
        <button className="action-button"  >
          <i class="fa-solid fa-pen-nib" onClick={() => handleStock(row)} ></i>
        </button>
      </div>
    ),
  };
  const featureEdit = {
    name: "feature",
    cell: (row) => (
      <div className="device-action-btn">
        <button className="action-button" onClick={() => handelFeaturepopup(row)}>
          {/* {console.log("plmko", row)} */}

          <i className="fa-solid fa-pen-to-square"></i>
        </button>
        <button className="action-button">
          <i className="fa-solid fa-trash-can" onClick={() => handleDeleteFeature(row.raw_material_features)} ></i>
        </button>
        <button className="action-button">
          <i class="fa-solid fa-circle-plus" onClick={handleAddFeature}></i>
        </button>

      </div>
    ),
  };


  // if (loading) {
  //   return <div className={`raw-main ${theme}-theme`}>
  //     Loading....
  //   </div>;
  // }

  // if (error) {
  //   return <div className={`raw-main ${theme}-theme`}>Error: {error}</div>;
  // }

  return (
    <div className={`raw-main ${theme}-theme`}>
      <div className="dash-dummy"></div>
      <div className="second-main">
        <div className="raw-content-main">
          <div className="raw-content-nav">
            <div className="raw-content-head">
              <p>Raw Materials</p>
            </div>
            <div className="raw-content-items">
              <div className="device-search">
                <i className="fa-solid fa-magnifying-glass"></i>
                <input
                  type="search"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <i className="fa-regular fa-square-plus add-model-icon" onClick={handleAdd}></i>

              {showHistoryPopup && (
                <div className="raw-add-popup-overlay">
                  <div className="raw-history-popup-content">
                    <button
                      className="raw-add-popup-close"
                      onClick={() => setShowHistoryPopup(false)}
                    >
                      <i className="fa-solid fa-x"></i>
                    </button>
                    <div className="raw-history-popup-table">
                      {/* {loading ? (
                      <p>Loading history...</p>
                    ) : error ? (
                      <p className="error">{error}</p>
                    ) : ( */}
                      <DataTable
                        columns={[
                          {
                            name: "ID",
                            selector: (row, index) => index + 1,
                            sortable: false,
                          },
                          { name: 'Quantity', selector: row => row.stock_quantity, sortable: true },
                          { name: 'IND', selector: row => row.unit_price_in_rupees, sortable: true },
                          { name: 'USD', selector: row => row.unit_price_in_dollars, sortable: true },
                          { name: 'Tax', selector: row => row.tax, sortable: true },
                          { name: 'Shipping Charge', selector: row => row.shipping_charge, sortable: true },
                          { name: 'Total', selector: row => row.total_price, sortable: true },
                          {
                            name: 'Date',
                            selector: row => new Date(row.updated_at).toLocaleDateString(),
                            sortable: true
                          },
                          {
                            name: 'Time',
                            selector: row => new Date(row.updated_at).toLocaleTimeString(),
                            sortable: true
                          },
                          { name: 'Updated By', selector: row => row.updated_by, sortable: true },
                        ]}
                        data={historyData}
                        fixedHeader
                        pagination
                      />
                      {/* )} */}
                    </div>

                  </div>
                </div>
              )}



              {addPopup && (
                <div className="raw-add-popup-overlay">
                  <div className="raw-add-popup-content">
                    <button
                      className="raw-add-popup-close"
                      onClick={() => setAddPopup(false)}
                    >
                      <i className="fa-solid fa-x"></i>
                    </button>
                    <h3>Add New Raw Material</h3>
                    <div className="raw-add-input-main">
                      <div className="raw-add-input">
                        <label htmlFor="add-image">Image:</label>
                        <input
                          type="file"
                          id="add-image"
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              const imageUrl = URL.createObjectURL(file);
                              setNewRawMaterial({ ...newRawMaterial, imageFile: file, imageUrl });
                            }
                          }}
                        />
                        <div className="raw-add-img">
                          <img
                            src={newRawMaterial.imageUrl || ""}
                            alt="Uploaded Preview"
                            style={{
                              width: "40px",
                              height: "40px",
                              objectFit: "cover",
                              borderRadius: "50%",
                              // display: newRawMaterial.imageUrl ? "block" : "none",
                            }}
                          />
                          <div
                            className="raw-change-img-btn"
                            onClick={() => document.getElementById("add-image").click()}
                          >
                            <p>Upload Photo</p>
                          </div>
                        </div>

                      </div>

                      <div className="raw-add-input">
                        <label>Component :</label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setNewRawMaterial({ ...newRawMaterial, component: e.target.value })
                          }
                        />
                      </div>
                      <div className="raw-add-input">
                        <label>Category :</label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setNewRawMaterial({ ...newRawMaterial, category: e.target.value })
                          }
                        />
                      </div>
                      <div className="raw-add-input">
                        <label>Stock :</label>
                        <input
                          type="number"
                          min={0}
                          onChange={(e) =>
                            setNewRawMaterial({ ...newRawMaterial, stock_quantity: e.target.value })
                          }
                        />
                      </div>
                      <div className="raw-add-input">
                        <label>Reorder Level :</label>
                        <input
                          type="number"
                          min={0}
                          onChange={(e) =>
                            setNewRawMaterial({ ...newRawMaterial, reorder_level: e.target.value })
                          }
                        />
                      </div>
                      <div className="raw-add-input">
                        <label>Package :</label>
                        <input
                          type="text"
                          min={0}
                          onChange={(e) =>
                            setNewRawMaterial({ ...newRawMaterial, package: e.target.value })
                          }
                        />
                      </div>
                      <div className="raw-add-input">
                        <label>IND Price :</label>
                        <input
                          type="number"
                          min={0}
                          onChange={(e) =>
                            setNewRawMaterial({
                              ...newRawMaterial,
                              unit_price_in_rupees: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="raw-add-input">
                        <label>USD Price :</label>
                        <input
                          type="number"
                          min={0}
                          onChange={(e) =>
                            setNewRawMaterial({
                              ...newRawMaterial,
                              unit_price_in_dollars: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="raw-add-input">
                        <label>Reference No :</label>
                        <input
                          type="number"
                          min={0}
                          onChange={(e) =>
                            setNewRawMaterial({
                              ...newRawMaterial,
                              reference_no: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="raw-add-input">
                        <label>Tax :</label>
                        <input
                          type="number"
                          min={0}
                          onChange={(e) =>
                            setNewRawMaterial({
                              ...newRawMaterial,
                              tax: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="raw-add-input">
                        <label>Shipping Charge :</label>
                        <input
                          type="number"
                          min={0}
                          onChange={(e) =>
                            setNewRawMaterial({
                              ...newRawMaterial,
                              shipping_charge: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <button className="raw-add-popup-save" onClick={handleAddSave}>
                      Save
                    </button>
                  </div>
                </div>
              )}


            </div>
          </div>
          <DataTable columns={[...baseColumns, actionColumn, stockColumn, ...featureColumns, featureEdit]} data={filteredMaterials} fixedHeader pagination />

          {stockpopup && selectedStock && (
            <div className="raw-popup-overlay">
              <div className="raw-popup-content">
                <button className="raw-popup-close" type="button" onClick={() => setStockPopup(false)}>
                  <i className="fa-solid fa-x"></i>
                </button>
                <h3>Update Stock for {selectedStock.category}</h3>
                <div className="raw-stock-edit-input-main">
                  <div className="raw-stock-edit-input">
                    <label>Unit Price :</label>
                    <input type="number" name="unit_price_in_rupees" onChange={handleInputChange} />
                  </div>

                  <div className="raw-stock-edit-input">
                    <label>Stock Quantity</label>
                    <input type="number" name="stock_quantity" onChange={handleInputChange} />
                  </div>
                  <div className="raw-stock-edit-input">
                    <label>Tax (%)</label>
                    <input type="number" name="tax" onChange={handleInputChange} />
                  </div>
                  <div className="raw-stock-edit-input">
                    <label>Shipping Charge (%)</label>
                    <input type="number" name="shipping_charge" onChange={handleInputChange} />
                  </div>
                </div>


                <button className='raw-stock-edit-btn' onClick={handleUpdateStock}>Save</button>
              </div>
            </div>
          )}
          {addfeaturepopup && (
            <div className="feature-edit-popup-overlay">
              <div className="feature-edit-popup-content">
                <button className="raw-popup-close" onClick={() => setAddFeaturePopup(false)}>
                  <i className="fa-solid fa-x"></i>
                </button>

                <h3>Add Features to Materials</h3>

                {/* Custom Multi-Select Dropdown */}
                <div className="custom-dropdown">
                  <div
                    className="dropdown-header"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                  >
                    {selectedMaterials.length > 0 ? (
                      <div className="selected-materials">
                        {selectedMaterials.map((mat) => (
                          <span key={mat.id} className="material-tag">
                            {mat.name} <i onClick={() => handleMaterialSelect(mat.id, mat.name)} className="fa-solid fa-x"></i>
                          </span>
                        ))}
                      </div>
                    ) : (
                      "Select Materials"
                    )}
                    <i className={`fa-solid fa-chevron-${dropdownOpen ? "up" : "down"}`}></i>
                  </div>

                  {dropdownOpen && (
                    <div className="dropdown-list">
                      {rawMaterials.map((material) => (
                        <div
                          key={material.id}
                          className={`dropdown-item ${selectedMaterials.some(mat => mat.id === material.id) ? "selected" : ""}`}
                          onClick={() => handleMaterialSelect(material.id, material.category)}
                        >
                          {material.category}
                          {selectedMaterials.some(mat => mat.id === material.id) && <i className="fa-solid fa-check"></i>}
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="raw-add-feature-input">
                    {newFeatures.map((feature, index) => (
                      <div key={index} className="feature-input">
                        <input
                          type="text"
                          placeholder="column Name"
                          value={feature.feature}
                          onChange={(e) => handleFeatureChange(index, "feature", e.target.value)}
                          required
                        />
                        <input
                          type="text"
                          placeholder="Feature Value"
                          value={feature.value}
                          onChange={(e) => handleFeatureChange(index, "value", e.target.value)}
                          required
                        />
                      </div>
                    ))}
                  </div>

                  {/* <button type="button" onClick={addFeatureField}>+ Add More</button> */}
                  <button className='raw-feature-add-button' type="submit">Submit</button>
                </form>
              </div>
            </div>
          )}




          {deletefeaturepopup && (
            <div className="feature-edit-popup-overlay">
              <div className="feature-edit-popup-content">
                <button className="raw-popup-close" onClick={() => setDeleteFeaturePopup(false)}>
                  <i className="fa-solid fa-x"></i>
                </button>
                <div className="feature-delete-popup-list-main">
                  <ul>
                    {console.log("")}
                    {selectedFeature &&
                      Object.entries(selectedFeature).map(([key, value]) => (
                        <li key={key}>
                          {console.log("keyyyy", value)}
                          {value.raw_material_value?.toString()}
                          <i className="fa-solid fa-trash-can" onClick={() => deleteFeature(value)}></i>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          )}

          {showfeaturepopup && (
            <div className="feature-edit-popup-overlay">
              <div className="raw-feature-edit-popup-content">
                <button className="raw-popup-close" onClick={() => setShowfeaturepopup(false)}>
                  <i className="fa-solid fa-x"></i>
                </button>
                <h3>Edit Features</h3>

                <div className="feature-edit-popup-input-main">
                  {selectedFeature.raw_material_features?.map((f, index) => (
                    <div key={index}>
                      <label>{f.raw_material_feature}:</label><br />
                      <input
                        type="text"
                        value={f.raw_material_value || ""} // Keep input even if empty
                        onChange={(e) => {
                          const updatedFeatures = selectedFeature.raw_material_features.map((feature) =>

                            feature.raw_material_feature === f.raw_material_feature
                              ? { ...feature, raw_material_value: e.target.value }
                              : feature
                          );
                          setSelectedFeature({ ...selectedFeature, raw_material_features: updatedFeatures });
                        }}
                      />

                    </div>
                  ))}
                </div>

                <button className='raw-feature-edit-btn' onClick={() => updateFeature(selectedFeature)}>Save</button>
              </div>
            </div>
          )}




          {deletePopup && (
            <div className="delete-popup-overlay">
              <div className="delete-popup-content">
                <p>Are you sure you want to delete this device?</p>
                <div className="delete-popup-btn">
                  <button className="delete-popup-confirm" onClick={handleDeleteConfirm}>
                    Confirm
                  </button>
                  <button className="delete-popup-cancel" onClick={handleCloseDeletePopup}>
                    Cancel
                  </button>
                </div>

              </div>
            </div>
          )}

          {showPopup && (
            <div className="raw-popup-overlay">
              <div className="raw-popup-content">
                <button
                  className="raw-popup-close"
                  type="button"
                  onClick={() => setShowPopup(false)}
                >
                  <i className="fa-solid fa-x"></i>
                </button>
                <form className="raw-popup-input">
                  <div className="raw-popup-image">
                    <img
                      src={editData?.image || "/path/to/default-image.jpg"}
                      alt="Profile"
                      style={{ width: 40, height: 40, borderRadius: "50%" }}
                    />
                    <input
                      type="file"
                      id="upload-image-input"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          const reader = new FileReader();
                          reader.onload = () => {
                            setEditData({ ...editData, image: reader.result, imageFile: file });
                          };
                          reader.readAsDataURL(file); // Convert file to base64 for preview
                        }
                      }}
                    />
                    <div
                      className="raw-change-img-btn"
                      onClick={() => document.getElementById("upload-image-input").click()}
                    >
                      <p>Change Photo</p>
                    </div>
                  </div>

                  <div>
                    <label>Stock :</label> <br />
                    <input
                      type="number"
                      min={0}
                      value={editData?.stock_quantity || ""}
                      onChange={(e) => setEditData({ ...editData, stock_quantity: e.target.value })}
                    />
                  </div>
                  <div>
                    <label>Reorder :</label> <br />
                    <input
                      type="number"
                      min={0}
                      value={editData?.reorder_level || ""}
                      onChange={(e) => setEditData({ ...editData, reorder_level: e.target.value })}
                    />
                  </div>
                  <div>
                    <label>IND :</label> <br />
                    <input
                      type="number"
                      min={0}
                      value={editData?.unit_price_in_rupees || ""}
                      onChange={(e) => setEditData({ ...editData, unit_price_in_rupees: e.target.value })}
                    />
                  </div>
                  <div>
                    <label>USD :</label> <br />
                    <input
                      type="number"
                      min={0}
                      value={editData?.unit_price_in_dollars || ""}
                      onChange={(e) => setEditData({ ...editData, unit_price_in_dollars: e.target.value })}
                    />
                  </div>

                </form>
                <button
                  type="button"
                  className="raw-add-popup-save"
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Raw;

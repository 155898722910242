import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDU3Hsw-EJ6O6KKwJN-IDddhR_TnErCpFw",
    authDomain: "aizoteq-c0002.firebaseapp.com",
    projectId: "aizoteq-c0002",
    storageBucket: "aizoteq-c0002.firebasestorage.app",
    messagingSenderId: "351655299170",
    appId: "1:351655299170:web:0fcf402a1f8eeeb922293b",
    measurementId: "G-JBFGVX7VXX"
  };
  

  // Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get messaging instance
const messaging = getMessaging(app);

export { messaging };



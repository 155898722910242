// import React, { useState } from 'react';
// import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
// import './App.css';
// import Sidebar from './components/sidebar/Sidebar';
// import Nav from './components/nav/Nav';
// import Dash from './components/dashbord/Dash';
// import Product from './components/products/Product';
// import { ThemeProvider } from './components/theme/ThemeContext';
// import Bill from './components/billing/Bill';
// import Settings from './components/settings/Settings';
// import Devices from './components/devices/Devices';
// import Profile from './components/profile/Profile';
// import Thing from './components/thing/Thing';
// import Customer from './components/customer/Customer';
// import Dealer from './components/dealers/Dealer';
// import Staff from './components/staff/Staff';
// import Raw from './components/sidebar/raw materials/Raw';
// import Validation from './components/validation/Validation';
// import Teq from './Teq';

// function App() {
//   const [isSidebarVisible, setSidebarVisible] = useState(false);

//   const toggleSidebar = () => {
//     setSidebarVisible((prev) => !prev);
//   };

//   return (
//     // <ThemeProvider>
//       <Router>
//         {/* <Nav toggleSidebar={toggleSidebar} />
//         <Sidebar isSidebarVisible={isSidebarVisible} /> */}
//         <div className="content">
//           <Routes>
//             {/* <Route path="/" element={<Dash />} />
//             <Route path="/products" element={<Product />} />
//             <Route path="/device" element={<Devices />} />
//             <Route path="/bill" element={<Bill />} />
//             <Route path="/profile" element={<Profile />} />
//             <Route path='/thing' element={<Thing/>}/>
//             <Route path='/customer' element={<Customer/>}/>
//             <Route path='/dealers' element={<Dealer/>}/>
//             <Route path='/staff' element={<Staff/>}/>
//             <Route path='/raw' element={<Raw/>}/> */}
//            <Route path="/" element={<Validation/>} />
//             <Route path='dashboard/*' element={<Teq/>}/>
//           </Routes>
//         </div>
//         {/* <Settings /> */}
//       </Router>
//     // </ThemeProvider>
//   );
// }

// export default App;


import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { ThemeProvider } from './components/theme/ThemeContext';
import Validation from './components/validation/Validation';
import Teq from './Teq';
import { messaging } from './firebase-config';
import { getToken, onMessage } from "firebase/messaging";


function App() {
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [tokens, setTokens] = useState(null);

  console.log('navigator',navigator);
  console.log('navigator service',navigator.serviceWorker);
  

  useEffect(() => {
    // Register service worker
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(registration => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch(error => {
          console.error('Service Worker registration failed:', error);
        });
    }

    // Request token for push notifications
   
    

    // Handle foreground notifications
    onMessage(messaging, (payload) => {
      console.log('Message received in foreground:', payload);
      // You can display a notification inside your app
      alert(`New Notification: ${payload.notification.title}`);
    });

  }, []);

  const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: 'BPV34sQU7Bnu2WtKUAC5w1Sue-U0aSJQZsSdHPtJxGTQ-qX7iSQANdk-r1KRfg2Yh-Lw5OmczCxHTuGnbx4hRSU'
      });
      if (token) {
        setTokens(token);
        console.log('Tokenssssss:', token);
      } else {
        console.error('No token available');
      }
    } else {
      console.error('Notification permission denied');
    }
  };
  requestNotificationPermission();
  const triggerNotification = () => {
    console.log("asdfgh");
    
    navigator.serviceWorker.ready.then(registration => {
      console.log("notification send",(registration));
      
      registration.showNotification("Test Notification", {
        body: "This is a manual test notification",
        icon: "/firebase-logo.png"
      });
    }).catch(error => {
      console.error("Service worker not ready:", error);
    });
  };

  
  const toggleSidebar = () => {
    setSidebarVisible((prev) => !prev);
  };

  const token = localStorage.getItem('authToken')
  console.log("token :", token)


  const backendurl = window.location.hostname === 'localhost'
    ? ''
    : process.env.REACT_APP_BACKEND_URL;

  const [userData, setUserData] = useState({})

  useEffect(() => {
    triggerNotification()
    fetchUserData();
  }, []); // Fetch user data only once on component mount

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${backendurl}/api/display/user`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        setUserData(data);
        console.log("fetch user data customer", data);

      } else {
        console.error("Failed to fetch user data.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  return (
    <ThemeProvider>
      <Router>
        <div className="content">
          <Routes>
            {console.log("app userrole",userData.userrole)}
            <Route path="/" element={<Validation />} />
            {userData.userrole !== "customer" && (
              <Route path="/dashboard/*" element={<Teq />} />
            )}
            {/* Other routes */}
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;

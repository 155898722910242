// import React, { useState } from 'react';
// import { Route, Routes } from 'react-router-dom';
// import Sidebar from './components/sidebar/Sidebar';
// import Nav from './components/nav/Nav';
// import Dash from './components/aizo-teq/dashbord/Dash';
// import Product from './components/aizo-teq/products/Product';
// import { ThemeProvider } from './components/theme/ThemeContext';
// import Bill from './components/aizo-teq/billing/Bill';
// import Settings from './components/settings/Settings';
// import Devices from './components/aizo-teq/devices/Devices';

// const Teq = () => {
//   const [isSidebarVisible, setSidebarVisible] = useState(false);

//   const toggleSidebar = () => {
//     setSidebarVisible((prev) => !prev);
//   };

//   return (
//     <div>
    //   <ThemeProvider>
    //     <Nav toggleSidebar={toggleSidebar} />
    //     <Sidebar isSidebarVisible={isSidebarVisible} />
    //     <div className="content">
    //       <Routes>
    //         <Route path="/" element={<Dash />} />
    //         <Route path="/products" element={<Product />} />          
    //         <Route path='/device' element={<Devices/>}/>
    //         <Route path="/bill" element={<Bill/>} />
    //       </Routes>
    //     </div>
    //     <Settings/>
    //   </ThemeProvider>
//     </div>
//   );
// };

// export default Teq;





// import React, { useState } from 'react';
// import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
// import Sidebar from './components/sidebar/Sidebar';
// import Nav from './components/nav/Nav';
// import Dash from './components/dashbord/Dash';
// import Product from './components/products/Product';
// import { ThemeProvider } from './components/theme/ThemeContext';
// import Bill from './components/billing/Bill';
// import Settings from './components/settings/Settings';
// import Devices from './components/devices/Devices';
// import Profile from './components/profile/Profile';
// import Thing from './components/thing/Thing';
// import Customer from './components/customer/Customer';
// import Dealer from './components/dealers/Dealer';
// import Staff from './components/staff/Staff';
// import Raw from './components/sidebar/raw materials/Raw';
// import Validation from './components/validation/Validation';

// function Teq() {
//   const [isSidebarVisible, setSidebarVisible] = useState(false);

//   const toggleSidebar = () => {
//     setSidebarVisible((prev) => !prev);
//   };

//   return (
//     <ThemeProvider>
//       <Router>
//         <Nav toggleSidebar={toggleSidebar} />
//         <Sidebar isSidebarVisible={isSidebarVisible} />
//         <div className="content">
//           <Routes>
//             <Route path="/" element={<Dash />} />
//             <Route path="/products" element={<Product />} />
//             <Route path="/device" element={<Devices />} />
//             <Route path="/bill" element={<Bill />} />
//             <Route path="/profile" element={<Profile />} />
//             <Route path='/thing' element={<Thing/>}/>
//             <Route path='/customer' element={<Customer/>}/>
//             <Route path='/dealers' element={<Dealer/>}/>
//             <Route path='/staff' element={<Staff/>}/>
//             <Route path='/raw' element={<Raw/>}/>
//           </Routes>
//         </div>
//         <Settings />
//       </Router>
//     </ThemeProvider>
//     // <>
//     // <Validation/>
//     // </>
//   );
// }

// export default Teq;


import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './components/sidebar/Sidebar';
import Nav from './components/nav/Nav';
import Dash from './components/dashbord/Dash';
import Product from './components/products/Product';
import Bill from './components/billing/Bill';
import Devices from './components/devices/Devices';
import Profile from './components/profile/Profile';
import Thing from './components/thing/Thing';
import Customer from './components/customer/Customer';
import Dealer from './components/dealers/Dealer';
import Staff from './components/staff/Staff';
import Raw from './components/raw materials/Raw';
import Settings from './components/settings/Settings';

function Teq() {
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible((prev) => !prev);
  };
  const token = localStorage.getItem('authToken')
  console.log("token :" , token)
  

  const backendurl = window.location.hostname === 'localhost'
  ? ''
  : process.env.REACT_APP_BACKEND_URL;

   const [userData, setUserData] = useState({})
  
   useEffect(() => {
          fetchUserData();
      }, []); // Fetch user data only once on component mount
  
      const fetchUserData = async () => {
          try {
              const response = await fetch(`${backendurl}/api/display/user`, {
                  method: "GET",
                  headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json',
                  },
                  withCredentials: true,
                  credentials: "include",
              });
  
              if (response.ok) {
                  const data = await response.json();
                  setUserData(data);
                  console.log("fetch user data customer",data);
                  
              } else {
                  console.error("Failed to fetch user data.");
              }
          } catch (error) {
              console.error("Error fetching user data:", error);
          }
      };
      

  return (
    <>
      <Nav toggleSidebar={toggleSidebar} />
      <Sidebar isSidebarVisible={isSidebarVisible} />
      <div className="content">
        <Routes>
          <Route path="/" element={<Dash />} />
          <Route path="/products" element={<Product />} />
          <Route path="/device" element={<Devices />} />
          <Route path="/bill" element={<Bill />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/thing" element={<Thing />} />
          <Route path="/customer" element={<Customer />} />
          <Route path="/dealers" element={<Dealer />} />
          <Route path="/staff" element={<Staff />} />
          <Route path="/raw" element={<Raw />} />
        </Routes>
      </div>
      <Settings />
    </>
  );
}

export default Teq;

import React, { useEffect, useState } from 'react';
import './Sidebar.css';
import logo from './side-img/logo.png'
import icon from './side-img/dashboard.png'
import Settings from '../settings/Settings';
import { useTheme } from '../theme/ThemeContext';
import { NavLink } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';



const Sidebar = ({ isSidebarVisible }) => {
  const token = localStorage.getItem('authToken')
  console.log("token :", token)


  const backendurl = window.location.hostname === 'localhost'
    ? ''
    : process.env.REACT_APP_BACKEND_URL;


  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { theme } = useTheme()



  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleItemClick = (index) => {
    setSelectedItem(index === selectedItem ? null : index); // Toggle the selection
  };

  const [userData, setUserData] = useState({})

  useEffect(() => {
    fetchUserData();
  }, []); // Fetch user data only once on component mount

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${backendurl}/api/display/user`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        setUserData(data);
        console.log("fetch user data customer", data);

      } else {
        console.error("Failed to fetch user data.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []); // Fetch user data only once on component mount


  //  const [theme, setTheme] = useState('light');

  //   const handleThemeChange = (newTheme) => {
  //     setTheme(newTheme);
  //     document.documentElement.setAttribute('data-theme', newTheme);
  //   };


  // const menuItems = [
  //   { id: 0, label: "Dashboard", iconClass: "fa-solid fa-house",},
  //   { id: 1, label: "Products", iconClass: "fa-solid fa-arrow-trend-up", },
  //   { id: 2, label: "Billing", iconClass: "fas fa-wallet", },
  // ];
  const shouldDisplay = (role) => {
    if (userData.userrole === "admin") return true; // Ensure admin sees all
    if (userData.userrole === "staff") return true;
    if (userData.userrole === "dealer" && role !== "usermanage" && role !== "raw") return true;
   
    // Hide everything for customers
};
console.log("rolllllll",userData)  

  return (
    <div className={`theme-main ${theme}-theme`}>
      <div className="sidebar-main" >
        <div className="sidebar-content-main">
          {userData.role !== "customer" && (
            <>
              <div className="dash-p" >
                <NavLink
                  to="/dashboard"
                  end
                  className={({ isActive }) =>
                    isActive ? 'dashboard active-link' : 'dashboard'
                  }
                >
                  <div className="p-tag">
                    <i class="fa-solid fa-house"></i>
                    <p >
                      Dashboard
                    </p>
                  </div>
                </NavLink>
              </div>
              <div className="dash-p" >
                <NavLink
                  to="/dashboard/device"
                  className={({ isActive }) =>
                    isActive ? 'dashboard active-link' : 'dashboard'
                  }
                >
                  <div className="p-tag">
                    <i class="fa-solid fa-hard-drive"></i>
                    <p >
                      Items
                    </p>
                  </div>
                </NavLink>
              </div>
              {/* <div className="dash-p" >
                <NavLink
                  to="/dashboard/products"
                  className={({ isActive }) =>
                    isActive ? 'dashboard active-link' : 'dashboard'
                  }
                >
                  <div className="p-tag">
                    <i class="fa-solid fa-hand-holding-dollar"></i>
                    <p >
                      warranty
                    </p>
                  </div>
                </NavLink>
              </div> */}
              <div className="dash-p" >
                <NavLink
                  to="/dashboard/thing"
                  className={({ isActive }) =>
                    isActive ? 'dashboard active-link' : 'dashboard'
                  }
                >
                  <div className="p-tag">
                    <i class="fa-brands fa-windows"></i>
                    <p >
                    Products
                    </p>
                  </div>
                </NavLink>
              </div>
              <div className="dash-p" >
                <NavLink
                  to="/dashboard/bill"
                  className={({ isActive }) =>
                    isActive ? 'dashboard active-link' : 'dashboard'
                  }
                >
                  <div className="p-tag">
                    <i class="fas fa-wallet"></i>
                    <p >
                      Billing
                    </p>
                  </div>
                </NavLink>
              </div>
              <div className="dash-p" >
                <NavLink
                  to="/dashboard/customer"
                  className={({ isActive }) =>
                    isActive ? 'dashboard active-link' : 'dashboard'
                  }
                >
                  <div className="p-tag">
                    <i class="fa-solid fa-users"></i>
                    <p >
                      Customer
                    </p>
                  </div>
                </NavLink>
              </div>
              {shouldDisplay("usermanage") && (
                <div className="dash-p" >
                  <NavLink
                    to="/dashboard/staff"
                    className={({ isActive }) =>
                      isActive ? 'dashboard active-link' : 'dashboard'
                    }
                  >
                    <div className="p-tag">
                      <i class="fa-solid fa-user-tie"></i>
                      <p >
                        User Manage
                      </p>
                    </div>
                  </NavLink>
                </div>
              )}
              {shouldDisplay("raw") && (
                <div className="dash-p" >
                  <NavLink
                    to="/dashboard/raw"
                    className={({ isActive }) =>
                      isActive ? 'dashboard active-link' : 'dashboard'
                    }
                  >
                    <div className="p-tag">
                      <i class="fa-solid fa-screwdriver-wrench"></i>
                      <p >
                        Raw Materials
                      </p>
                    </div>
                  </NavLink>
                </div>
              )}
            </>
          )}

        </div>
      </div>

      {/* ............................................................................. Response ........................................................................ */}

      <div className={`sidebar-main1 ${isSidebarVisible ? 'visible' : ''}`}>
        <div className="sidebar-content-main">
          <div className="dash-p" >
            <NavLink
              to="/dashboard"
              end
              className={({ isActive }) =>
                isActive ? 'dashboard active-link' : 'dashboard'
              }
            >
              <div className="p-tag">
                <i class="fa-solid fa-house"></i>
                <p >
                  Dashboard
                </p>
              </div>
            </NavLink>
          </div>
          <div className="dash-p" >
            <NavLink
              to="/dashboard/device"
              className={({ isActive }) =>
                isActive ? 'dashboard active-link' : 'dashboard'
              }
            >
              <div className="p-tag">
                <i class="fa-solid fa-hard-drive"></i>
                <p >
                  Items
                </p>
              </div>
            </NavLink>
          </div>
          {/* <div className="dash-p" >
            <NavLink
              to="/dashboard/products"
              className={({ isActive }) =>
                isActive ? 'dashboard active-link' : 'dashboard'
              }
            >
              <div className="p-tag">
                <i class="fa-solid fa-hand-holding-dollar"></i>
                <p >
                  Warranty
                </p>
              </div>
            </NavLink>
          </div> */}
          <div className="dash-p" >
            <NavLink
              to="/dashboard/thing"
              className={({ isActive }) =>
                isActive ? 'dashboard active-link' : 'dashboard'
              }
            >
              <div className="p-tag">
                <i class="fa-brands fa-windows"></i>
                <p >
                  Products
                </p>
              </div>
            </NavLink>
          </div>
          <div className="dash-p" >
            <NavLink
              to="/dashboard/bill"
              className={({ isActive }) =>
                isActive ? 'dashboard active-link' : 'dashboard'
              }
            >
              <div className="p-tag">
                <i class="fas fa-wallet"></i>
                <p >
                  Billing
                </p>
              </div>
            </NavLink>
          </div>
          <div className="dash-p" >
            <NavLink
              to="/dashboard/customer"
              className={({ isActive }) =>
                isActive ? 'dashboard active-link' : 'dashboard'
              }
            >
              <div className="p-tag">
                <i class="fa-solid fa-users"></i>
                <p >
                  Customer
                </p>
              </div>
            </NavLink>
          </div>

          <div className="dash-p" >
            <NavLink
              to="/dashboard/staff"
              className={({ isActive }) =>
                isActive ? 'dashboard active-link' : 'dashboard'
              }
            >
              <div className="p-tag">
                <i class="fa-solid fa-user-tie"></i>
                <p >
                  User Manage
                </p>
              </div>
            </NavLink>
          </div>
          <div className="dash-p" >
            <NavLink
              to="/dashboard/raw"
              className={({ isActive }) =>
                isActive ? 'dashboard active-link' : 'dashboard'
              }
            >
              <div className="p-tag">
                <i class="fa-solid fa-screwdriver-wrench"></i>
                <p >
                  Raw Materials
                </p>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

import React, { useCallback, useEffect, useState } from 'react';
import './Nav.css';
import Cropper from 'react-easy-crop';
import defaultUserImg from './nav-img/user.png';
import logo1 from './nav-img/logo_black.png';
import logo2 from './nav-img/logo_white.png';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../theme/ThemeContext';
import { getCroppedImg } from '../../getCroppedImg';

const Nav = ({ toggleSidebar }) => {
    const token = localStorage.getItem('authToken');
    const userId = localStorage.getItem('userDetails');

    const { theme } = useTheme();
    const navigate = useNavigate();

    const [showPopup, setShowPopup] = useState(false);
    const [userData, setUserData] = useState({});
    const [barcancel, setBarCancel] = useState(false);
    const [logo, setLogo] = useState(logo1);
    const [showProfile, setShowProfile] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [imageSrc, setImageSrc] = useState(null);
    const [showCropper, setShowCropper] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [editedName, setEditedName] = useState("");
    const [intervalId, setIntervalId] = useState(null);
    const backendurl = window.location.hostname === 'localhost'
        ? ''
        : process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        setLogo(theme === 'dark' ? logo2 : logo1);
    }, [theme]);



    const handleshowprofile = () => {
        setShowProfile(true)
    }



    useEffect(() => {
        if (userData.name) {
            setEditedName(userData.name); // Populate input with the current name
        }
    }, [userData]);

    const handleNameChange = (e) => {
        setEditedName(e.target.value);
    };

    const handleSaveName = async () => {
        if (!editedName.trim()) {
            alert("Name cannot be empty!");
            return;
        }

        try {
            const userDetails = JSON.parse(userId); // Convert JSON string to object
            const id = userDetails.id;
            const response = await fetch(`${backendurl}/api/users/${id}/name`, {
                method: "PUT",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: editedName }),

            });
            // console.log("abcdefghi");

            if (response.ok) {
                const updatedUser = await response.json();
                setUserData(updatedUser.user); // Update UI with new user data
                setShowProfile(false); // Close the profile popup
            } else {
                console.error("Failed to update name.");
            }
        } catch (error) {
            console.error("Error updating name:", error);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []); // Fetch user data only once on component mount

    const fetchUserData = async () => {
        try {
            const response = await fetch(`${backendurl}/api/display/user`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                credentials: "include",
            });

            if (response.ok) {
                const data = await response.json();
                setUserData(data);
                console.log("fetch user data", data);

            } else {
                console.error("Failed to fetch user data.");
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };
    // Polling to fetch user data every minute (60000ms)
    //  useEffect(() => {
    //     fetchUserData(); // Initial fetch
    //     const id = setInterval(fetchUserData, 1000); // Set interval to fetch every minute
    //     setIntervalId(id);

    //     return () => clearInterval(id); // Clear the interval when the component unmounts
    // }, []);


    const handleNotification = () => {
        setShowNotification(!showNotification);
        setShowPopup(false);
        fetchUnreadCount(); // Refresh unread count when opening notifications
    };
    const fetchNotifications = useCallback(async () => {
        try {
            const response = await fetch(`${backendurl}/dashboard/api/display/alert/notifications`); // Adjust URL if needed
            const data = await response.json();
            setNotifications(data); // Store fetched notifications
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    }, []);
   

    const deleteNotification = async (id) => {
        console.log("iddddd", id)
        try {
            const response = await fetch(`${backendurl}/dashboard/api/delete/notifications/${id}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error("Failed to delete notification");
            }

            // Remove the deleted notification from state
            setNotifications((prevNotifications) =>
                prevNotifications.filter((notification) => notification.id !== id)
            );
        } catch (error) {
            console.error("Error deleting notification:", error);
        }
    };

    const fetchUnreadCount = useCallback(async () => {
        try {
            const response = await fetch(`${backendurl}/dashboard/api/alert_notifications/unread-count`);
            const data = await response.json();
            setUnreadCount(data.unread_count || 0); // Set unread count
        } catch (error) {
            console.error("Error fetching unread count:", error);
        }
    }, []);

    useEffect(() => {
        fetchNotifications();
        fetchUnreadCount();
    }, [fetchNotifications, fetchUnreadCount]);

    const handleMarkAsRead = async (id) => {
        try {
            await fetch(`${backendurl}/dashboard/api/alert_notifications/${id}/toggle-read`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            fetchNotifications();
            fetchUnreadCount();
        } catch (error) {
            console.error('Error marking as read:', error);
        }
    };

    const handleProfilePicChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result);
                setShowCropper(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const onCropComplete = useCallback((_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCropSave = async () => {
        try {
            const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
            const formData = new FormData();
            formData.append('profilepic', croppedImage);

            const response = await fetch(`${backendurl}/api/users/profile-pic`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                withCredentials: true,
                body: formData,
                credentials: 'include',
            });

            if (response.ok) {
                const updatedUserData = await response.json();
                setUserData(updatedUserData);
                fetchUserData();
            } else {
                console.error('Failed to update profile picture.');
            }
            setShowCropper(false);
        } catch (error) {
            console.error('Error updating profile picture:', error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('userDetails');
        sessionStorage.clear();
        navigate('/');
    };

    const handlePopup = () => {
        setShowPopup(!showPopup);
        setShowNotification(false)
    };

    return (
        <>
            <div className={`nav-main ${theme}-theme`}>
                <div className="nav-logo">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="nav-content-main">
                    <div className="nav-content-bar" onClick={toggleSidebar}>
                        <i className={`fa-solid ${barcancel ? "fa-x" : "fa-bars"}`}></i>
                    </div>
                    <div className="nav-content-logo">
                        <img src={logo} alt="Logo" />
                    </div>
                    <div className="nav-content">
                        <i className="fa-solid fa-bell" onClick={handleNotification} style={{ cursor: "pointer", position: "relative",fontSize: "25px" }}> {unreadCount > 0 && (
                            <span className="notification-badge">{unreadCount}</span>
                        )}</i>
                        <div className="nav-profile" onClick={handlePopup}>
                            <img
                                src={userData.profilepic || defaultUserImg}
                                alt="User Profile"
                                onError={(e) => e.target.src = defaultUserImg}
                            />
                        </div>
                    </div>

                    {showNotification && (
                <div className="notification-popup-menu">
                    <div className="notification-popup-menu2">
                        {notifications.length > 0 ? (
                        notifications.map((notification) => (
                            <div
                            key={notification.id}
                            className={`notification-message-data ${!notification.read ? "unread" : ""}`}
                            onClick={() => handleMarkAsRead(notification.id)} // Handle click to mark as read
                        >
                                <p className="notification-title">{notification.title || "New Notification"}</p>
                                <p className="notification-body">{notification.body || "No message available"}</p>
                                <div className="notification-bar-btns">
                                    <p>{new Date(notification.sent_at).toLocaleString()}</p>
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        <button onClick={() => deleteNotification(notification.id)}>Delete</button>
                                        
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No new notifications</p>
                    )}
                    </div>
                    
                </div>
            )}

                    {showPopup && (
                        <div className="nav-popup-menu">
                            <div className="nav-popup-profile1">
                                <p>{userData.name || "User"}</p>
                                {console.log("userdataaaaaa", userData)
                                }
                            </div>
                            <div className="nav-popup-profile2">
                                <img
                                    src={userData.profilepic || defaultUserImg}
                                    alt="User Popup Profile"
                                    onError={(e) => e.target.src = defaultUserImg}
                                />
                            </div>
                            <div className="nav-popup-profile-details">
                                <p>{userData.userrole || "Role"}</p>
                                <span>{userData.username || "email@example.com"}</span>
                            </div>
                            <div className="nav-popup-item-main">
                                <div className="nav-popup-item" onClick={handleshowprofile}>
                                    <i className="fa-solid fa-user-pen"></i>
                                    <span>Edit Profile</span>
                                </div>
                                {/* <div className="nav-popup-item">
                                    <i className="fa-solid fa-headset"></i>
                                    <span>Help</span>
                                </div> */}
                                <div className="nav-popup-item" onClick={handleLogout}>
                                    <i className="fa-solid fa-arrow-right-from-bracket"></i>
                                    <span>Logout</span>
                                </div>
                            </div>
                        </div>
                    )}

                    {showProfile && (
                        <div className="nav-popup-overlay">
                            <div className="nav-popup-content">
                                <button className="nav-popup-close" onClick={() => setShowProfile(false)}>
                                    <i className="fa-solid fa-x"></i>
                                </button>
                                <div className="nav-popup-items">
                                    <div className="nav-popup-edit-profile">
                                        <div className="nav-edit-profile">
                                            <img
                                                src={userData.profilepic || defaultUserImg}
                                                alt="User Popup Profile"
                                                onError={(e) => e.target.src = defaultUserImg}
                                            />
                                            <span>{userData.name || "User"}</span>
                                        </div>
                                        <div className="nav-change-profile-btn">
                                            <label htmlFor="profilePicUpload">Change Photo</label>
                                            <input
                                                type="file"
                                                id="profilePicUpload"
                                                accept="image/*"
                                                onChange={handleProfilePicChange}
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                        {showCropper && (
                                            <div className="cropper-modal">
                                                <div className="cropper-container">
                                                    <Cropper
                                                        image={imageSrc}
                                                        crop={crop}
                                                        zoom={zoom}
                                                        aspect={1}
                                                        onCropChange={setCrop}
                                                        onZoomChange={setZoom}
                                                        onCropComplete={onCropComplete}
                                                    />
                                                    <div className="cropper-btn">
                                                        <button className="cropper-close" onClick={() => setShowCropper(false)}><i class="fa-solid fa-xmark"></i></button>
                                                        <button className="cropper-save" onClick={handleCropSave}>Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                    <div className="nav-popup-edit-input">
                                        <label htmlFor="editName">Name</label>
                                        <input
                                            type="text"
                                            id="editName"
                                            value={editedName}
                                            onChange={handleNameChange}
                                        />
                                    </div>
                                    {/* <div className="nav-popup-edit-input">
                                        <label htmlFor="">Address</label>
                                        <input type="text" />
                                    </div> */}
                                </div>
                                <div className="nav-popup-save-btn">
                                    <button className="popup-save" onClick={handleSaveName}>
                                        Save
                                    </button>
                                </div>

                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Nav;

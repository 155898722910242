import React, { useState } from 'react';
import './Validation.css';
import logo from './valid-img/logo_white.png';
import { data, useNavigate } from 'react-router-dom';

const Validation = () => {

  const backendurl = window.location.hostname === 'localhost'
    ? ''
    : process.env.REACT_APP_BACKEND_URL;


  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    password: '',
    rePassword: '',
    verificationInitiated: false,
  });
  
  const [view, setView] = useState('login'); // 'login', 'signup', or 'reset'
  const [otpPopup, setOtpPopup] = useState(false);
  const [otp, setOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // To handle inline error messages

  // Update form state on input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const displayError = (message) => {
    setErrorMessage(message);
    setTimeout(() => setErrorMessage(''), 5000); // Clear message after 5 seconds
  };

  const handleSignup = async () => {
    if (formData.password !== formData.rePassword) {
      displayError('Passwords do not match');
      return;
    }
    try {
      const { fullName, email, phoneNumber, password } = formData;
      const userName = email;

      console.log('Form data:', formData);
      sessionStorage.setItem('formData', JSON.stringify(formData));
      // sessionStorage.setItem('Data', JSON.stringify(data));

      const response = await fetch(`${backendurl}/signup/app/customer/signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ fullName, userName, email, phoneNumber, password }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Signup response:', data);
        sessionStorage.setItem('Data', JSON.stringify(data));

        displayError(data.message || 'Signup successful!');
        setOtpPopup(true); // Show OTP popup
      } else {
        const errorData = await response.json();
        displayError(errorData.message || 'Signup failed!');
      }
    } catch (error) {
      console.error('Error during signup:', error);
      displayError('Something went wrong. Please try again later.');
    }
  };

  const handleOtpSubmit = async () => {
    const data = sessionStorage.getItem("Data")
    console.log("dataaaa" ,data);
    
    try {
      const response = await fetch(`${backendurl}/signup/verify-otp`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: formData.email, otp , fullName: formData.fullName,jwtsub: data.userSub , role: data.role}),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('OTP verification response:', data);

        displayError(data.message || 'OTP verified successfully!');
      setOtpPopup(false); // Close the OTP popup
      setView('login'); // Redirect to dashboard after successful verification
      } else {
        const errorData = await response.json();
        displayError(errorData.message || 'OTP verification failed!');
      }
    } catch (error) {
      console.error('Error during OTP verification:', error);
      displayError('Something went wrong. Please try again later.');
    }
  };


  const handleResendOtp = async () => {
    try {
      const response = await fetch(`${backendurl}/signup/resend-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: formData.email, // Replace this with the actual username value
        }),
        credentials: 'include', // If you're using cookies for session management
      });

      const result = await response.json();

      if (response.ok) {
        displayError(result.message); // Show success message
      } else {
        displayError(result.message || 'Failed to resend OTP'); // Show error message
      }
    } catch (error) {
      console.error('Error:', error);
      displayError('An error occurred while resending OTP');
    }
  };


  const handleLogin = async () => {
    try {
      const { email, password } = formData;

      // Send login request to the backend
      const response = await fetch(`${backendurl}/signup/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: email, password }),
      });

      // Check if the response is successful
      if (response.ok) {
        const data = await response.json();

        // Store token and user details in sessionStorage for session management
        localStorage.setItem('authToken', data.token);
        localStorage.setItem('userSub', data.jwtsub);
        // sessionStorage.setItem('userId', data.user);
        localStorage.setItem('userDetails', JSON.stringify(data.user)); // Storing the user details
        localStorage.setItem('userRole', JSON.stringify(data.user.userrole)); // Storing the user details


        // Log the response for debugging
        console.log('Login successful:', data.user.userrole);


        // alert(data.message || 'Login successful!');     
        navigate('/dashboard'); // Redirect to dashboard or desired page
      } else {
        const errorData = await response.json();
        displayError(errorData.message || 'Login failed!');
      }
    } catch (error) {
      console.error('Error during login:', error);
      displayError('Something went wrong. Please try again later.');
    }
  };


  const handleResetPassword = async () => {
    if (!formData.email) {
      displayError('Please enter your email');
      return;
    }
  
    try {
      const response = await fetch(`${backendurl}/signup/forgotpassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: formData.email }),
      });
  
      if (response.ok) {
        const data = await response.json();
        displayError(data.message || 'Password reset initiated! Check your email.');
  
        // Update state to show verification inputs
        setFormData((prevFormData) => ({
          ...prevFormData,
          verificationInitiated: true,
        }));
      } else {
        const errorData = await response.json();
        displayError(errorData.message || 'Password reset failed!');
      }
    } catch (error) {
      console.error('Error during password reset:', error);
      displayError('Something went wrong. Please try again later.');
    }
  };
  

  const handleVerificationCodeSubmit = async () => {
    const { email: username, verificationCode, newPassword } = formData;
  
    if (!username || !verificationCode || !newPassword) {
      displayError("Please fill in all fields.");
      return;
    }
  
    try {
      const response = await fetch(`${backendurl}/signup/confirmforgotpassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username, // Email as username
          verificationCode,
          newPassword,
        }),
      })
      if (response.ok) {
        const data = await response.json();
        displayError(data.message || "Password reset successful!");
        setFormData((prevFormData) => ({
          ...prevFormData,
          verificationInitiated: false,
          verificationCode: "",
          newPassword: "",
        }));
        setView("login"); // Redirect to login view after successful reset
      } else {
        const errorData = await response.json();
        displayError(errorData.message || "Failed to reset password.");
      }
    } catch (error) {
      console.error("Error during password reset confirmation:", error);
      displayError("Something went wrong. Please try again later.");
    }
  };




  return (
    <div className="valid-main">
      <div className="valid-main-btn">
        {view !== 'reset' && (
          <button onClick={() => setView(view === 'login' ? 'signup' : 'login')}>
            {view === 'login' ? 'Signup' : 'Login'}
          </button>
        )}
        {view === 'reset' && (

          <button onClick={() => setView('login')}>
            Login
          </button>



        )}
      </div>
      <img src={logo} alt="Logo" />
      <div
        className="valid-content-main"
        style={{
          height: view === 'reset' ? '250px' : view === 'login' ? '350px' : '450px' // Adjust height dynamically
        }}
      >
        {errorMessage && <p style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</p>} {/* Inline error messages */}
        {view === 'reset' ? (
          <div className="valid-reset-password">
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleInputChange}
            />
            {!formData.verificationInitiated && (
              <div className="valid-buttons">
                <button onClick={handleResetPassword}>Reset Password</button>
              </div>
            )}

            {/* Inputs for verification code and new password (visible after reset success) */}
            {formData.verificationInitiated && (
              <>
                <input
                  type="text"
                  name="verificationCode"
                  placeholder="Enter Verification Code"
                  value={formData.verificationCode}
                  onChange={handleInputChange}
                />
                <input
                  type="password"
                  name="newPassword"
                  placeholder="Enter New Password"
                  value={formData.newPassword}
                  onChange={handleInputChange}
                />
                <div className="valid-buttons">
                  <button onClick={handleVerificationCodeSubmit}>
                    Submit Verification Code
                  </button>
                </div>
              </>
            )}
          </div>
        ) : view === 'login' ? (
          <div className="valid-login-input">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
            />
            <div
              className="valid-resetpass-login"
              
            >
              <p onClick={() => setView('reset')}>Forget Password !</p>
            </div>
          </div>
        ) : (
          <div className="valid-signup-input">
            <input
              type="text"
              name="fullName"
              placeholder="Name"
              value={formData.fullName}
              onChange={handleInputChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="phoneNumber"
              placeholder="Phone"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
            />
            <input
              type="password"
              name="rePassword"
              placeholder="Re-enter Password"
              value={formData.rePassword}
              onChange={handleInputChange}
            />

          </div>
        )}
        <div className="valid-buttons">
          {view === 'login' && <button onClick={handleLogin}>Login</button>}
          {view === 'signup' && <button onClick={handleSignup}>Signup</button>}
        </div>
      </div>
      {/* OTP Popup */}
      {otpPopup && (
        <div className="otp-popup-overlay">
          <div className="otp-popup">
            <h2>Enter OTP</h2>
            <div className="otp-popup-input">
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              <div className="otp-popup-resend">
                <p onClick={handleResendOtp}>Resend Otp</p>
              </div>
            </div>


            <div className="valid-buttons">
              <button onClick={handleOtpSubmit}>Submit OTP</button>
            </div>
          </div>
        </div>

      )}
    </div>
  );
};

export default Validation;

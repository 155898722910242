import React, { useEffect, useState } from "react";
import "./Devices.css";
import { useTheme } from "../theme/ThemeContext";
import DataTable from "react-data-table-component";
import omit from "lodash.omit";
import imggg1 from './model-img/5sw &fan.png6365054edeac1.png'
import imggg2 from './model-img/5sw &fan3d.png6365054ee8fee.png'
import imggg3 from './model-img/5sw and fan( 3d b).png6365054f003fe.png'
import imggg4 from './model-img/5sw and fan(w).png6365054f0f5a0.png'


const Devices = () => {
  const token = localStorage.getItem('authToken')
  console.log("token :", token)
  const backendurl = window.location.hostname === 'localhost'
    ? ''
    : process.env.REACT_APP_BACKEND_URL;


  const { theme } = useTheme();
  const [showPopup, setShowPopup] = useState(false);
  const [checkpopup, setCheckPopup] = useState(false)
  const [selectedColumns, setSelectedColumns] = useState(new Set());
  const [availableColumns, setAvailableColumns] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState('');
  const [editData, setEditData] = useState(null);
  const [devices, setDevices] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [addPopup, setAddPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false)
  const [featurepopup, setfeaturepopup] = useState(false)
  const [featureImages, setFeatureImages] = useState([]);
  const [webImages, setWebImages] = useState([]);
  const [newFeature, setNewFeature] = useState("");
  const [mainImage, setMainImage] = useState(null)
  const [features, setFeatures] = useState([]);
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [editImagePopup, setEditImagePopup] = useState(false);
  const [editFeaturePopup, setEditFeaturePopup] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const [rawMaterials, setRawMaterials] = useState([]);
  const [components, setComponents] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState("");
  const [requiredQty, setRequiredQty] = useState("");
  const [modelId, setModelId] = useState(null);
  const [modelIds, setModelIds] = useState(null)
  const [actionPopup, setActionPopup] = useState(false); // New state for the action popup
  const [actionData, setActionData] = useState(null);
  const [materials, setMaterials] = useState([]);
  const [Qty, setQty] = useState("");
  const [showrawDeletePopup, setShowrawDeletePopup] = useState(false); // State to control popup visibility
  const [selectedRawMaterial, setSelectedRawMaterial] = useState(null); // State to store selected raw material
  const [deleterawMaterials, setDeleteRawMaterials] = useState([]);
  const [userData, setUserData] = useState({})
  const [userRole, setUserRole] = useState(null); // Store user role in state
  const [featureeditpopup, setfeatureeditpopup] = useState(false)
  const [featuredeletepopup, setfeaturedeletepopup] = useState(false)
  const [featureaddpopup, setfeatureaddpopup] = useState(false)
  const [feature, setFeature] = useState("");
  const [featureValue, setFeatureValue] = useState("");
  const [modelRow, setModelRow] = useState({ features: [] });
  const [selectedFiles, setSelectedFiles] = useState([]);

  console.log("feature modelIddd", editData);


  const handleDeletewebImage = async (imageid) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this image?");
    if (!confirmDelete) return;

    try {
      const response = await fetch(`${backendurl}/dashboard/api/delete/web/images/${imageid}`, {
        method: "DELETE",
        headers: {
          'Authorization': ` Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        // Remove the image from the local state
        setImages((prevImages) => prevImages.filter((image) => image.modelId !== modelId));

        alert("Image deleted successfully!");
      } else {
        const data = await response.json();
        alert(data.message || "Failed to delete the image.");
      }
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("An error occurred while deleting the image. Please try again.");
    }
  };

  const handleUploadwebimg = async (modelId) => {
    console.log("modelid22", modelId)
    if (!selectedFiles.length) {
      alert("Please select images to upload");
      return;
    }


    const formData = new FormData();
    Array.from(selectedFiles).forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await fetch(`${backendurl}/dashboard/api/upload/webimage/${modelId}`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const newFeatureImages = data.imagePaths.map((imageUrl, index) => ({
          id: Date.now() + index, // Temporary unique ID
          images: [{ image_id: Date.now() + index, image_url: imageUrl }],
        }));

        setFeatureImages((prev) => [...prev, ...newFeatureImages]);
        setNewImages([]); // Clear selected files
        setEditImagePopup(false);
        alert("Images uploaded successfully");
        fetchModelData(modelId); // Refresh data

      } else {
        const error = await response.json();
        alert(error.message || "Failed to upload images");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      alert("Error uploading images");
    }
  };
  

  const handleEditFeaturePopup = (row) => {
    setModelIds(row);
    setEditFeaturePopup(true);
    // fetchModelData(row.model_id); // Fetch images when opening popup
  };




  const handlewebFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };


  const fetchModelData = async (modelId) => {
    console.log("feature", modelId);
    try {
      const response = await fetch(`${backendurl}/dashboard/api/display/images/${modelId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("Fetched Dataaaaaaaaaaa:", data);
      setFeatureImages(data.feature_images || []);
      setWebImages(data.web_images || []);
      setModelId(data.model_id)
      setMainImage(`${backendurl}${data.feature_images[0].images[0].image_url}`);
    } catch (error) {
      console.error("Error fetching model data:", error);
    }
  };



  const handleImageClick = (imageUrl) => {
    console.log("Clicked Image URL:", imageUrl);
    setMainImage(imageUrl);
  };



  useEffect(() => {
    fetchDevices(searchQuery);
  }, [searchQuery]);


  const [visibleColumns, setVisibleColumns] = useState(
    JSON.parse(localStorage.getItem("visibleColumns")) || []
  );
  console.log('visibleColumns', columns)
  console.log('visibleColumns', visibleColumns)
  // const storedColumns = JSON.parse(localStorage.getItem("visibleColumns"));
  // useEffect(() => {
  //   const storedColumns = JSON.parse(localStorage.getItem("visibleColumns"));

  //   if (storedColumns) {
  //     setVisibleColumns(storedColumns);
  //   } else {
  //     setVisibleColumns(availableColumns);
  //   }
  // }, [availableColumns]);

  // useEffect(() => {
  //   localStorage.setItem("visibleColumns", JSON.stringify(visibleColumns));
  // }, [visibleColumns]);

  // Save the selected columns to localStorage

  const handleFeatureEdit = (row) => {
    setModelRow({ ...row }); // Copy row to state
    setfeatureeditpopup(true);
  };

  const handleFeatureChange = (index, value) => {
    setModelRow((prevState) => {
      const updatedFeatures = prevState.features.map((feature, i) =>
        i === index ? { ...feature, feature_value: value } : feature
      );
      return { ...prevState, features: updatedFeatures };
    });
  };

  const handleSaveFeature = async () => {
    console.log("model row:", modelRow);

    try {
      for (const feature of modelRow.features) {
        if (!feature.feature_id) continue; // Skip if feature ID is missing

        const response = await fetch(
          `${backendurl}/dashboard/api/model/features/update/${modelRow.price_id}/${feature.feature_id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
              feature: feature.feature,
              feature_value: feature.feature_value,
            }),
          }
        );

        const data = await response.json();
        if (!response.ok) {
          alert(`Error updating ${feature.feature}: ${data.error}`);
        }
      }

      alert("Features updated successfully!");
      setfeatureeditpopup(false);
      fetchDevices()
    } catch (error) {
      console.error("Failed to update feature:", error);
      alert("Failed to update feature");
    }
  };


  const handleFeatureDelete = async (row) => {
    setModelRow(row)
    setfeaturedeletepopup(true)
  }

  const FeatureDelete = async (model_id, feature_id) => {
    if (!window.confirm("Are you sure you want to delete this feature?")) {
      return;
    }

    try {
      const response = await fetch(
        `${backendurl}/dashboard/api/model/features/delete/${model_id}/${feature_id}`,
        {
          method: "DELETE",
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        alert("Feature deleted successfully!");
        // Remove the deleted feature from the UI
        setModelRow((prevState) => ({
          ...prevState,
          features: prevState.features.filter((f) => f.feature_id !== feature_id),
        }));
        fetchDevices()
      } else {
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error("Failed to delete feature:", error);
      alert("Failed to delete feature");
    }
  };




  const handleFeatureAdd = async (row) => {
    setModelRow(row)
    setfeatureaddpopup(true)
  }

  const AddFeature = async (modelId, feature, featureValue) => {
    try {
      const response = await fetch(`${backendurl}/dashboard/api/model/features/add/${modelId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`, // Ensure token is available
        },
        body: JSON.stringify({
          feature: feature,
          feature_value: featureValue,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        alert("Feature added successfully!");
        console.log("Added Feature:", data);
        // Refresh the feature list (if needed)
        setfeatureaddpopup(false)
        fetchDevices()
      } else {
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error("Failed to add feature:", error);
      alert("Failed to add feature");
    }
  };





  console.log(userRole)
  const fetchUserData = async () => {
    try {
      const response = await fetch(`${backendurl}/api/display/user`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        setUserData(data);
        console.log("fetch user data customer", data);

      } else {
        console.error("Failed to fetch user data.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);






  // const handleDeleteFeature = async (featureId) => {
  //   const confirmDelete = window.confirm(
  //     "Are you sure you want to delete this feature?"
  //   );
  //   if (!confirmDelete) return;

  //   try {
  //     const response = await fetch(`${backendurl}/dashboard/delete-feature/${featureId}`, {
  //       method: "DELETE",
  //     });

  //     if (response.ok) {
  //       alert(`Feature with ID ${featureId} deleted successfully.`);
  //       setFeatures((prevFeatures) =>
  //         prevFeatures.filter((feature) => feature.id !== featureId)
  //       ); // Update the state
  //       fetchModelData()
  //     } else {
  //       const error = await response.json();
  //       alert(`Error: ${error.error}`);
  //     }
  //   } catch (error) {
  //     console.error("Error deleting feature:", error);
  //     alert("An unexpected error occurred. Please try again.");
  //   }
  // };

  // const handleAddFeature = async (modelIds) => {
  //   if (!newFeature.trim()) {
  //     alert("Feature cannot be empty");
  //     return;
  //   }

  //   const modelId = editData?.id; // Assuming each row has a unique ID
  //   try {
  //     const response = await fetch(`${backendurl}/dashboard/api/add-features/${modelIds}`, {
  //       method: "POST",
  //       headers: {
  //         'Authorization': ` Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ features: [newFeature] }),
  //     });
  //     console.log("modeliddddd", modelId)

  //     if (response.ok) {
  //       const data = await response.json();
  //       alert("Feature added successfully");
  //       setFeatures((prevFeatures) => [...prevFeatures, { feature: newFeature }]);
  //       setNewFeature(""); // Clear the input
  //       setEditFeaturePopup(false)
  //     } else {
  //       const error = await response.json();
  //       alert(`Error: ${error.message}`);
  //     }
  //   } catch (error) {
  //     console.error("Error adding feature:", error);
  //     alert("An unexpected error occurred. Please try again.");
  //   }
  // };


  const handlerawDeleteClick = (row) => {
    setSelectedRawMaterial(row); // Store the selected raw material
    setShowrawDeletePopup(true); // Show the delete confirmation popup
  };

  const handleClosePopup = () => {
    setShowrawDeletePopup(false); // Close the popup
  };

  // const fetchModelData = async (modelId) => {
  //   console.log("alksncan", modelId);

  //   try {
  //     const response = await fetch(`${backendurl}/dashboard/api/display/model/features/${modelId}`, {
  //       headers: {
  //         'Authorization': ` Bearer ${token}`,
  //         'Content-Type': 'application/json',
  //         // Dynamic origin based on the frontend domain
  //       },

  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to fetch model data");
  //     }
  //     const data = await response.json();
  //     console.log(`data of features${data}`)
  //     console.log(`features${data.features}`)
  //     console.log(`image features${data.images}`)
  //     setFeatures(data.features || []);
  //     setImages(data.images || []);
  //     setModelId(modelId)
  //     console.log(`image ${data.images.image_url}`)
  //     console.log(`images1 ${data.images}`)
  //     console.log(`featuressss ${data.features.feature}`)
  //     const img1 = data.images?.[0];
  //     console.log(`img1${img1}`)
  //     console.log(`imgwe1${img1.image_url}`)
  //     setMainImage(img1.image_url); // Set the first image as the main image
  //   } catch (error) {
  //     console.error("Error fetching model data:", error);
  //   }
  // };
  // const handleImageFeature = (image) => {
  //   setMainImage(image);
  // };


  const handlerawDeleteConfirm = async () => {
    if (!selectedRawMaterial) return;

    try {
      // Make DELETE request to API
      const response = await fetch(`${backendurl}/dashboard/api/delete/thingrawmaterials/${selectedRawMaterial.id}`, {
        headers: {
          'Authorization': ` Bearer ${token}`,
          'Content-Type': 'application/json',
          // Dynamic origin based on the frontend domain
        },
        method: 'DELETE',
      });

      const result = await response.json();

      if (response.ok) {
        // Remove the deleted raw material from the state
        setDeleteRawMaterials((prevMaterials) =>
          prevMaterials.filter((material) => material.id !== selectedRawMaterial.id)
        );
        setShowrawDeletePopup(false); // Close the popup
        fetchRawMaterials(modelId)
      } else {
        console.error('Error:', result.message);
        // Handle error (e.g., show a notification or message to the user)
      }
    } catch (error) {
      console.error('Error deleting raw material:', error);
      // Handle fetch error (e.g., show a notification or message to the user)
    }
  };




  const [newModel, setNewModel] = useState({
    model: "",
    mrp: "",
    retail_price: "",
    sgst: "",
    cgst: "",
    igst: "",
    discount: "",
    warranty_period: "",
  });


  const handleEditClick = (row) => {
    setActionPopup(true);
    setActionData(row); // Pass the data of the row being edited
  };

  // Handle delete button click
  const handleDeleteClick = (row) => {
    setDeletePopup(true);
    setDeleteData(row); // Pass the data of the row being deleted
  };

  // Close action popup
  // const handleCloseActionPopup = () => {
  //   setActionPopup(false);
  //   setActionData(null);
  // };

  useEffect(() => {
    const savedColumns = localStorage.getItem("selectedColumns");
    if (savedColumns) {
      setSelectedColumns(JSON.parse(savedColumns)); // Load saved selection
    }
    fetchDevices();
  }, []);

  const fetchDevices = async (query = "") => {
    try {
      const response = await fetch(
        `${backendurl}/dashboard/api/display/prices-table?search=${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      );
      const data = await response.json();
      const formattedData = Array.isArray(data.data) ? data.data : [];
      setDevices(formattedData);
      generateDynamicColumns(formattedData);
    } catch (error) {
      console.error("Error fetching devices:", error);
    }
  };




  const fetchRawMaterials = async (modelId) => {
    console.log("Fetching raw materials for modelId:", modelId); // Debug
    try {
      const response = await fetch(`${backendurl}/dashboard/api/model/${modelId}`, {
        headers: {
          'Authorization': ` Bearer ${token}`,
          'Content-Type': 'application/json',
          // Dynamic origin based on the frontend domain
        },
        // method: 'DELETE',
      });
      const data = await response.json();
      if (response.ok) {
        setRawMaterials(data.raw_materials);
        setModelId(modelId);
        console.log("Opening edit popup..."); // Debug
        setEditPopup(true); // Opens the popup
        console.log("editPopup changed:", editPopup);
      } else {
        console.error("Error fetching raw materials:", data.error);
      }
    } catch (error) {
      console.error("Error fetching raw materials:", error);
    }
  };


  const handleSaveActionPopup = async () => {
    if (!editData) {
      console.error("No data to update. Please check if editData is properly set.");
      return; // Prevent function from continuing if editData is null
    }

    const { modelId, rawMaterialId } = editData;

    // Validate required quantity
    if (!Qty || Qty <= 0) {
      alert("Please provide a valid quantity greater than zero.");
      return;
    }

    console.log("Saving data:", { Qty, modelId, rawMaterialId }); // Debugging

    try {
      const response = await fetch(`${backendurl}/dashboard/api/update/raw/${modelId}/${rawMaterialId}`, {
        method: "PUT",
        headers: {
          'Authorization': ` Bearer ${token}`,
          'Content-Type': 'application/json',
          // Dynamic origin based on the frontend domain
        },
        body: JSON.stringify({ requiredQty: Qty }), // Send the requiredQty from state
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Failed to update raw material: ${errorMessage}`);
      }

      const updatedMaterial = await response.json();
      console.log("Updated material:", updatedMaterial); // Debugging the response
      fetchRawMaterials(modelId)

      setMaterials((prevMaterials) =>
        prevMaterials.map((material) =>
          material.rawMaterialId === updatedMaterial.data.raw_material_id &&
            material.modelId === updatedMaterial.data.model_id
            ? { ...material, required_qty: updatedMaterial.data.required_qty }
            : material
        )
      );

      // Close the action popup after successful save
      handleCloseActionPopup();
    } catch (error) {
      console.error("Error updating raw material:", error);
    }
  };

  // Function to close the action popup
  const handleCloseActionPopup = () => {
    setActionPopup(false);
    setRequiredQty(""); // Clear the input field when closing the popup
  };




  useEffect(() => {
    const fetchComponents = async () => {
      console.log(`url${backendurl}`)
      try {
        const response = await fetch(`${backendurl}/dashboard/api/raw_materials`, {
          headers: {
            'Authorization': ` Bearer ${token}`,
            'Content-Type': 'application/json',
            // Dynamic origin based on the frontend domain
          },
          // method: 'DELETE',
        });
        const data = await response.json();
        console.log("Fetched components data:", data); // Debug here
        console.log("Fetched components:", data.raw_materials); // Debug here
        setComponents(data.raw_materials || []);

      } catch (error) {
        console.error("Error fetching components:", error);
      }
    };

    fetchComponents();
  }, []);

  const handleAddRawMaterial = async (modelid) => {
    // if (!selectedComponent || !requiredQty) {
    //   alert("Please select a component and enter the required quantity.");
    //   return;
    // }

    try {
      console.log(`url${backendurl}`)
      const response = await fetch(`${backendurl}/dashboard/api/model/${modelid}/add-raw-material`, {
        method: "POST",
        headers: {
          'Authorization': ` Bearer ${token}`,
          'Content-Type': 'application/json',
          // Dynamic origin based on the frontend domain
        },
        body: JSON.stringify({
          raw_material_id: selectedComponent, // Assuming this is the ID
          required_qty: requiredQty,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        alert("Raw material added successfully!");
        fetchRawMaterials(modelId)
      }
      //   // Fetch the updated list of raw materials or update it manually
      //   const updatedRawMaterials = await fetch(`${backendurl}/dashboard/api/model/${modelid}/raw-materials`);
      //   const rawMaterialsData = await updatedRawMaterials.json();
      //   setRawMaterials(rawMaterialsData);
      // } else {
      //   const error = await response.json();
      //   alert(error.error || "Failed to add raw material");
      // }
    } catch (err) {
      console.error("Error adding raw material:", err);
      alert("An error occurred while adding the raw material.");
    }
  };

  // useEffect(() => {
  //   fetchDevices(searchQuery);
  // }, [searchQuery]);

  const handleEdit = (row) => {
    setEditData(row);
    console.log("qwert", row)
    setShowPopup(true);
  };
  // const handlecolumns = (row) => {
  //   setEditData(row);
  //   console.log("qwert",row)
  //   setCheckPopup(true);
  // };
  const handleEditPopup = (row) => {
    setEditData(row);
    setEditPopup(true)
  }


  const handleDeleteClickee = (row) => {
    setDeleteData(row);
    setDeletePopup(true);
  };

  const handlefeaturepopup = (row) => {
    fetchModelData(row.price_id)
    console.log("handlefeaturepopup", row);

    setEditData(row);
    setfeaturepopup(true)
  }

  const handleEditImagePopup = (row) => {
    console.log("handleEditImagePopup", row);

    setEditData(row)
    setEditImagePopup(true)
  }

  // const handleImageClick = (image) => {
  //   setMainImage(image); // Update the main image
  // };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const totalImages = images.length + files.length;
      if (totalImages > 4) {
        alert(`You can only upload 4 images.`);
      } else {
        setNewImages([...newImages, ...files]);
      }
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      await fetch(`${backendurl}/dashboard/api/delete/price_table/${deleteData.id}`, {
        method: "DELETE",
        headers: {
          'Authorization': ` Bearer ${token}`,
          'Content-Type': 'application/json',
          // Dynamic origin based on the frontend domain
        },
      });
      setDevices(devices.filter((device) => device.id !== deleteData.id));
      setDeletePopup(false);
      fetchDevices()
    } catch (error) {
      console.error("Error deleting device:", error);
    }
  };


  const handleCloseDeletePopup = () => {
    setDeletePopup(false);
    setDeleteData(null);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };


  const validateWarrantyPeriod = (warranty) => {
    const validFormat = /^(?:(\d+)\s+years?)?\s*(?:(\d+)\s+months?)?$/i;
    return validFormat.test(warranty);
  };

  const handleSaveEdit = async (priceid) => {
    const { id, mrp, retail_price, discount, sgst, cgst, igst, warranty_period } = editData;

    if (!validateWarrantyPeriod(warranty_period)) {
      alert("Invalid warranty period format. Use formats like '2 years', '6 months', or '1 year 6 months'.");
      return;
    }

    if (!sgst || !cgst || !igst) {
      alert("Please provide valid tax values.");
      return;
    }

    try {
      const response = await fetch(`${backendurl}/dashboard/api/update/price_table/${priceid}`, {
        method: "PUT",
        headers: {
          'Authorization': ` Bearer ${token}`,
          'Content-Type': 'application/json',
          // Dynamic origin based on the frontend domain
        },
        body: JSON.stringify({ mrp, retail_price, discount, sgst, cgst, igst, warranty_period }),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Failed to update device: ${errorMessage}`);
      }

      const updatedDevice = await response.json();
      setDevices((prevDevices) =>
        prevDevices.map((device) => (device.id === updatedDevice.id ? updatedDevice : device))

      );
      setShowPopup(false);
      setEditData(null);
      fetchDevices()
    } catch (error) {
      console.error("Error updating device:", error);
    }
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(`${backendurl}/dashboard/api/display/model/features/${modelId}`, {
          headers: {
            // 'Authorization': ` Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });;
        console.log("modeliddddd", modelId)
        if (response.ok) {
          const data = await response.json();
          setImages(data.images); // Assume backend returns { images: [...] }
        } else {
          console.error("Failed to fetch images");
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, [modelId]);


  const handleUploadimg = async (modelId) => {
    console.log("modelid22", modelId)
    const formData = new FormData();
    newImages.forEach((image) => {
      formData.append("images", image);
    });

    try {
      const response = await fetch(`${backendurl}/dashboard/api/upload-images/${modelId}`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const newFeatureImages = data.imagePaths.map((imageUrl, index) => ({
          id: Date.now() + index, // Temporary unique ID
          images: [{ image_id: Date.now() + index, image_url: imageUrl }],
        }));

        setFeatureImages((prev) => [...prev, ...newFeatureImages]);
        setNewImages([]); // Clear selected files
        setEditImagePopup(false);
        alert("Images uploaded successfully");
        fetchModelData(modelId); // Refresh data

      } else {
        const error = await response.json();
        alert(error.message || "Failed to upload images");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      alert("Error uploading images");
    }
  };


  const handleDeleteImage = async (imageid) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this image?");
    if (!confirmDelete) return;

    try {
      const response = await fetch(`${backendurl}/dashboard/api/delete-image/${imageid}`, {
        method: "DELETE",
        headers: {
          'Authorization': ` Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        // Remove the image from the local state
        setImages((prevImages) => prevImages.filter((image) => image.modelId !== modelId));

        alert("Image deleted successfully!");
      } else {
        const data = await response.json();
        alert(data.message || "Failed to delete the image.");
      }
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("An error occurred while deleting the image. Please try again.");
    }
  };



  const handleAddNewModel = async () => {
    console.log("New Model Data:", newModel); // Debugging

    try {
      const response = await fetch(`${backendurl}/dashboard/api/create/price_table`, {
        method: "POST",
        headers: {
          'Authorization': ` Bearer ${token}`,
          'Content-Type': 'application/json',
          // Dynamic origin based on the frontend domain
        },
        body: JSON.stringify(newModel),
      });

      if (!response.ok) {
        throw new Error("Failed to add new model");
      }

      const addedDevice = await response.json();
      setDevices([...devices, addedDevice]);
      setAddPopup(false);
      setNewModel({
        model: "",
        mrp: "",
        retail_price: "",
        sgst: "",
        cgst: "",
        igst: "",
        discount: "",
        warranty_period: "",
      });
      fetchDevices()
    } catch (error) {
      console.error("Error adding new model:", error);
    }
  };


  useEffect(() => {
    const storedRole = localStorage.getItem('userRole');  // You can fetch it from API or context as well
    setUserRole(storedRole); // Set the role into state
  }, []);

  if (userData.userrole === "customer" /*|| userData.userrole === "staff"*/) {
    return null; // Hide the page for customers and staffconsole.log("anshiddddd",userData.userrole)
  }




  // const generateDynamicColumns = (data, userRole) => {
  //   let featureColumns = new Set();
  //   let attributeColumns = new Set();

  //   // Extracting features & attributes dynamically
  //   data.forEach((device) => {
  //     device.features?.forEach((feature) => featureColumns.add(feature.feature));
  //     device.attributes?.forEach((attribute) => attributeColumns.add(attribute.attributeName));
  //   });

  //   // Static columns
  //   const staticColumns = [
  //     { name: "ID", selector: (row, index) => index + 1, sortable: true },
  //     { name: "Model", selector: (row) => row.model, sortable: true },
  //     { name: "MRP", selector: (row) => row.mrp, sortable: true },
  //     { name: "Retail Price", selector: (row) => row.retail_price, sortable: true },
  //     { name: "SGST", selector: (row) => row.sgst, sortable: true },
  //     { name: "CGST", selector: (row) => row.cgst, sortable: true },
  //     { name: "IGST", selector: (row) => row.igst, sortable: true },
  //     { name: "Discount", selector: (row) => row.discount, sortable: true },
  //     { name: "Last Modification", selector: (row) => row.lastmodified, sortable: true },
  //     {
  //       name: "Warranty",
  //       selector: (row) => {
  //         let warranty = row.warranty_period;
  //         if (typeof warranty === "object" && warranty !== null) {
  //           const years = warranty.years || 0;
  //           const months = warranty.months || 0;
  //           return years && months
  //             ? `${years} years ${months} months`
  //             : years
  //             ? `${years} years`
  //             : months
  //             ? `${months} months`
  //             : "No warranty";
  //         }
  //         return "Invalid warranty data";
  //       },
  //       sortable: true,
  //     },
  //   ];

  //   // Dynamic feature & attribute columns
  //   const dynamicFeatureColumns = Array.from(featureColumns).map((feature) => ({
  //     name: feature,
  //     selector: (row) => row.features?.find((f) => f.feature === feature)?.feature_value || "-",
  //     sortable: true,
  //   }));

  //   const dynamicAttributeColumns = Array.from(attributeColumns).map((attribute) => ({
  //     name: attribute,
  //     selector: (row) => row.attributes?.find((attr) => attr.attributeName === attribute)?.attributeValue || "-",
  //     sortable: true,
  //   }));

  //   // General Action column
  //   const actionColumn = {
  //     name: "Features",
  //     cell: (row) => (
  //       <button className="raw-button" onClick={() => handlefeaturepopup(row)}>
  //         <i className="fa-solid fa-gears"></i>
  //       </button>
  //     ),
  //   };

  //   let finalColumns = [...staticColumns, ...dynamicFeatureColumns, ...dynamicAttributeColumns, actionColumn];
  //  // Check if there's any user with the role 'admin'

  //  const adminUser = userData.find(user => user.userrole === "admin");
  //   // Add admin-specific columns
  //   if (adminUser) {
  //     const adminColumns = [
  //       {
  //         name: "Raw Materials",
  //         cell: (row) => (
  //           <button className="raw-button" onClick={() => fetchRawMaterials(row.price_id)}>
  //             <i className="fa-solid fa-screwdriver-wrench"></i>
  //           {console.log("rowwww id",row)}
  //           </button>

  //         ),
  //       },
  //       {
  //         name: "Action",
  //         cell: (row) => (
  //           <div className="device-action-btn">
  //             <button className="action-button" onClick={() => handleEdit(row)}>
  //               <i className="fa-solid fa-pen-to-square"></i>
  //             </button>
  //             <button className="action-button" onClick={() => handleDeleteClick(row)}>
  //               <i className="fa-solid fa-trash-can"></i>
  //             </button>
  //           </div>
  //         ),
  //       },
  //     ];

  //     finalColumns = [...finalColumns, ...adminColumns];
  //   }

  //   // Set final columns in state
  //   setColumns(finalColumns);
  //   console.log("user data",)
  // };
  // Simulate user login or fetching data (in real-world apps, this would be an API call)


  console.log("user data", userData)
  // const generateDynamicColumns = (data) => {
  //   let featureColumns = new Set();
  //   let attributeColumns = new Set();

  //   data.forEach((device) => {
  //     device.features?.forEach((feature) =>
  //       featureColumns.add(feature.feature)
  //     );
  //     device.attributes?.forEach((attribute) =>
  //       attributeColumns.add(attribute.attributeName)
  //     );
  //   });

  //   // Static columns
  //   const staticColumns = [
  //     { name: "ID", selector: (row, index) => index + 1, sortable: true },
  //     { name: "Model", selector: (row) => row.model, sortable: true },
  //     { name: "MRP", selector: (row) => row.mrp, sortable: true },
  //     { name: "Retail Price", selector: (row) => row.retail_price, sortable: true },
  //     { name: "SGST", selector: (row) => row.sgst, sortable: true },
  //     { name: "CGST", selector: (row) => row.cgst, sortable: true },
  //     { name: "IGST", selector: (row) => row.igst, sortable: true },
  //     { name: "Discount", selector: (row) => row.discount, sortable: true },
  //     { name: "Last Modification", selector: (row) => row.lastmodified, sortable: true },
  //     {
  //       name: "Warranty",
  //       selector: (row) => {
  //         let warranty = row.warranty_period;
  //         if (typeof warranty === "object" && warranty !== null) {
  //           const years = warranty.years || 0;
  //           const months = warranty.months || 0;
  //           return years && months
  //             ? `${years} years ${months} months`
  //             : years
  //               ? `${years} years`
  //               : months
  //                 ? `${months} months`
  //                 : "No warranty";
  //         }
  //         return "Invalid warranty data";
  //       },
  //       sortable: true,
  //     },
  //   ];

  //   // Dynamic columns
  //   // const dynamicFeatureColumns = Array.from(featureColumns).map((feature) => ({
  //   //   name: feature,
  //   //   selector: (row) =>
  //   //     row.features?.find((f) => f.feature === feature)?.feature_value || "-",
  //   //   sortable: true,
  //   // }));

  //   const dynamicFeatureColumns = Array.from(featureColumns)
  //     .filter((feature) => feature) // Remove null or undefined values
  //     .map((feature) => ({
  //       name: feature,
  //       selector: (row) =>
  //         row.features?.find((f) => f.feature === feature)?.feature_value || "-",
  //       sortable: true,
  //     }));


  //   const dynamicAttributeColumns = Array.from(attributeColumns)
  //     .filter((attribute) => attribute) // Remove null or undefined values
  //     .map((attribute) => ({
  //       name: attribute,
  //       selector: (row) =>
  //         row.attributes?.find((attr) => attr.attributeName === attribute)?.attributeValue || "-",
  //       sortable: true,
  //     }));


  //   // Action columns
  //   const actionColumn = {
  //     name: "Images",
  //     cell: (row) => (
  //       <button className="raw-button" onClick={() => handlefeaturepopup(row)}>
  //         <i class="fa-solid fa-image"></i>
  //       </button>
  //     ),
  //   };

  //   let finalColumns = [...staticColumns, ...dynamicFeatureColumns, ...dynamicAttributeColumns, actionColumn];

  //   // Admin-specific columns (if applicable)
  //   if (true) {
  //     const adminColumns = [
  //       {
  //         name: "Raw Materials",
  //         cell: (row) => (
  //           <button className="raw-button" onClick={() => fetchRawMaterials(row.price_id)}>
  //             <i className="fa-solid fa-screwdriver-wrench"></i>
  //           </button>
  //         ),
  //       },
  //       {
  //         name: "Action",
  //         cell: (row) => (
  //           <div className="device-action-btn">
  //             <button className="action-button" onClick={() => handleEdit(row)}>
  //               <i className="fa-solid fa-pen-to-square"></i>
  //             </button>
  //             <button className="action-button" onClick={() => handleDeleteClick(row)}>
  //               <i className="fa-solid fa-trash-can"></i>
  //             </button>
  //           </div>
  //         ),
  //       },
  //     ];
  //     finalColumns = [...finalColumns, ...adminColumns];
  //   }

  //   const featureEdit = [
  //     {
  //       name: "feature",
  //       cell: (row) => (
  //         <div className="device-action-btn">
  //           {console.log("feature roww", row)}

  //           <button className="action-button" >
  //             <i className="fa-solid fa-pen-to-square" onClick={() => handleFeatureEdit(row)}></i>
  //           </button>
  //           <button className="action-button" >
  //             <i className="fa-solid fa-trash-can" onClick={() => handleFeatureDelete(row)}></i>
  //           </button>
  //           <button className="action-button">
  //             <i class="fa-solid fa-circle-plus" onClick={() => handleFeatureAdd(row)}></i>
  //           </button>
  //         </div>
  //       ),
  //     }
  //   ]
  //   finalColumns = [...finalColumns, ...featureEdit]

  //   setColumns(finalColumns);

  //   // Load visible columns from localStorage if available
  //   const storedColumns = JSON.parse(localStorage.getItem("visibleColumns"));
  //   if (storedColumns) {
  //     setVisibleColumns(storedColumns);
  //   } else {
  //     setVisibleColumns(finalColumns); // Default: show all columns
  //   }
  //   // setAvailableColumns(finalColumns); // Store all columns for popup
  //   // setSelectedColumns(finalColumns);  // Initially select all columns for DataTable
  //   // setColumns(finalColumns);
  // };




  const generateDynamicColumns = (data) => {
    let featureColumns = new Set();
    let attributeColumns = new Set();

    data.forEach((device) => {
      device.features?.forEach((feature) => featureColumns.add(feature.feature));
      device.attributes?.forEach((attribute) => attributeColumns.add(attribute.attributeName));
    });

    const staticColumns = [
      { id: "id", name: "ID", selector: (row, index) => index + 1, sortable: true },
      { id: "model", name: "Model", selector: (row) => row.model, sortable: true },
      { id: "mrp", name: "MRP", selector: (row) => row.mrp, sortable: true },
      { id: "retail_price", name: "Retail Price", selector: (row) => row.retail_price, sortable: true },
      { id: "sgst", name: "SGST", selector: (row) => row.sgst, sortable: true },
      { id: "cgst", name: "CGST", selector: (row) => row.cgst, sortable: true },
      { id: "igst", name: "IGST", selector: (row) => row.igst, sortable: true },
      { id: "discount", name: "Discount", selector: (row) => row.discount, sortable: true },
      { id: "lastmodified", name: "Last Modification", selector: (row) => row.lastmodified, sortable: true },
    ];

    const dynamicFeatureColumns = Array.from(featureColumns).map((feature, index) => ({
      id: `feature_${index}`,
      name: feature,
      selector: (row) =>
        row.features?.find((f) => f.feature === feature)?.feature_value || "-",
      sortable: true,
    }));

    const dynamicAttributeColumns = Array.from(attributeColumns).map((attribute, index) => ({
      id: `attribute_${index}`,
      name: attribute,
      selector: (row) =>
        row.attributes?.find((attr) => attr.attributeName === attribute)?.attributeValue || "-",
      sortable: true,
    }));

    const actionColumn = {
      id: "images",
      name: "Images",
      cell: (row) => (
        <button className="raw-button" onClick={() => handlefeaturepopup(row)}>
          <i className="fa-solid fa-image"></i>
        </button>
      ),
    };

    let finalColumns = [...staticColumns, ...dynamicFeatureColumns, ...dynamicAttributeColumns, actionColumn];



    if (true) {
      const adminColumns = [
        {
          id: "raw materials",
          name: "Raw Materials",
          cell: (row) => (
            <button className="raw-button" onClick={() => fetchRawMaterials(row.price_id)}>
              <i className="fa-solid fa-screwdriver-wrench"></i>
            </button>
          ),
        },
        {
          id: "action",
          name: "Price Edit",
          cell: (row) => (
            <div className="device-action-btn">
              <button className="action-button" onClick={() => handleEdit(row)}>
                <i className="fa-solid fa-pen-to-square"></i>
              </button>
              <button className="action-button" onClick={() => handleDeleteClick(row)}>
                <i className="fa-solid fa-trash-can"></i>
              </button>
            </div>
          ),
        },
      ];
      finalColumns = [...finalColumns, ...adminColumns];
    }

    const featureEdit = [
      {
        id: "feature",
        name: "feature",
        cell: (row) => (
          <div className="device-action-btn">
            {console.log("feature roww", row)}

            <button className="action-button" >
              <i className="fa-solid fa-pen-to-square" onClick={() => handleFeatureEdit(row)}></i>
            </button>
            <button className="action-button" >
              <i className="fa-solid fa-trash-can" onClick={() => handleFeatureDelete(row)}></i>
            </button>
            <button className="action-button">
              <i class="fa-solid fa-circle-plus" onClick={() => handleFeatureAdd(row)}></i>
            </button>
          </div>
        ),
      }
    ]
    finalColumns = [...finalColumns, ...featureEdit]
    const storedColumns = JSON.parse(localStorage.getItem("visibleColumns"));
    console.log(` storedColumns==${storedColumns}`)
    if (storedColumns) {
      setVisibleColumns(storedColumns);
    } else {
      // localStorage.setItem("visibleColumns", JSON.stringify(visibleColumns))
      setVisibleColumns(finalColumns);
    }
    setColumns(finalColumns);
  };



  //  const toggleColumn = (columnId) => {
  //   setVisibleColumns((prev) =>
  //     prev.some((col) => col.id === columnId)
  //       ? prev.filter((col) => col.id !== columnId)
  //       : [...prev, columns.find((col) => col.id === columnId)]
  //   );
  // };
  const toggleColumn = (columnId) => {
    setVisibleColumns((prevVisibleColumns) => {
      const columnExists = prevVisibleColumns.some((col) => col.id === columnId);

      let updatedColumns;
      if (columnExists) {
        // Remove column
        updatedColumns = prevVisibleColumns.filter((col) => col.id !== columnId);
      } else {
        // Add column
        const columnToAdd = columns.find((col) => col.id === columnId);
        updatedColumns = columnToAdd ? [...prevVisibleColumns, columnToAdd] : prevVisibleColumns;
      }
      console.log(`uploadupdatedColumns ==${updatedColumns}`)
      localStorage.setItem("visibleColumns", JSON.stringify(updatedColumns))
      const storedColumns = JSON.parse(localStorage.getItem("visibleColumns"));
      console.log(` storedColumns22==${storedColumns}`)
      // 🛑 Move localStorage update inside a separate effect for better performance
      return updatedColumns;
    });
  };

  // ✅ Store updated columns in localStorage whenever they change
  //   useEffect(() => {
  //     localStorage.setItem("visibleColumns", JSON.stringify(visibleColumns));
  //   }, [visibleColumns]); // Ensures re-rendering
  // });
  //   };




  const handlecheckSaveEdit = () => {
    setColumns(selectedColumns); // Update only DataTable columns
    setCheckPopup(false); // Close the popup
  };



  return (
    <div className={`device-main ${theme}-theme`}>
      <div className="dash-dummy"></div>
      <div className="second-main">
        <div className="device-content-main">
          <div className="device-content">
            <div className="device-content-head">
              <p>Device Details</p>
            </div>
            <div className="device-content-items">
              <div className="device-search">
                <i className="fa-solid fa-magnifying-glass"></i>
                <input
                  type="search"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
              {userData.userrole === "admin" && (
                <>
                  <i class="fa-solid fa-circle-check" style={{ cursor: "pointer" }} onClick={() => setCheckPopup(true)}></i>
                  <i
                    className="fa-regular fa-square-plus add-model-icon"
                    onClick={() => setAddPopup(true)}
                  ></i>
                </>

              )}
            </div>
            {checkpopup && (
              <div className="model-edit-popup-overlay">
                <div className="check-popup-column-content">
                  <button className="model-edit-popup-close" type="button" onClick={() => setCheckPopup(false)} >
                    <i className="fa-solid fa-x"></i>
                  </button>
                  <div className="check-popup-column-list">
                    {console.log("awdx", columns)}

                    <ul>
                      {/* {availableColumns.map((col, index) => (
                        <li key={index}>
                          <input
                            type="checkbox"
                            checked={selectedColumns.some((selected) => selected.name === col.name)}
                            onChange={() => toggleColumn(col)}
                          />
                          {col.name}
                        </li>
                      ))} */}
                      <ul>
                        {columns.map((col) => (
                          <li key={col.id}>
                            <input
                              type="checkbox"
                              checked={visibleColumns.some((vc) => vc.id === col.id)}
                              onChange={() => toggleColumn(col.id)}
                            />
                            {col.name}
                          </li>
                        ))}
                      </ul>

                    </ul>
                  </div>
                  {/* <button
                    type="button"
                    className="model-edit-popup-save"
                    onClick={handlecheckSaveEdit}
                  >
                    Save
                  </button> */}
                </div>
              </div>
            )}
            {showPopup && (
              <div className="model-edit-popup-overlay">
                <div className="model-edit-popup-content">
                  <button
                    className="model-edit-popup-close"
                    type="button"
                    onClick={() => setShowPopup(false)}
                  >
                    <i className="fa-solid fa-x"></i>
                  </button>
                  <form className="model-edit-popup-input">
                    <div>
                      <label>MRP:</label> <br />
                      <input
                        type="number"
                        min={0}
                        value={editData?.mrp || ""}
                        onChange={(e) => setEditData({ ...editData, mrp: e.target.value })}
                      />
                    </div>
                    <div>
                      <label>Retail Price:</label> <br />
                      <input
                        type="number"
                        min={0}
                        value={editData?.retail_price || ""}
                        onChange={(e) => setEditData({ ...editData, retail_price: e.target.value })}
                      />
                    </div>
                    <div>
                      <label>Discount:</label> <br />
                      <input
                        type="number"
                        min={0}
                        value={editData?.discount || ""}
                        onChange={(e) => setEditData({ ...editData, discount: e.target.value })}
                      />
                    </div>
                    <div className="model-add-input-tax">
                      <div className="sgst tax">
                        <label>SGST:</label>
                        <input
                          type="number"
                          min={0}
                          value={editData?.sgst || ""}
                          onChange={(e) => setEditData({ ...editData, sgst: e.target.value })}
                        />
                      </div>
                      <div className="cgst tax">
                        <label>CGST:</label>
                        <input
                          type="number"
                          min={0}
                          value={editData?.cgst || ""}
                          onChange={(e) => setEditData({ ...editData, cgst: e.target.value })}
                        />
                      </div>
                      <div className="igst tax">
                        <label>IGST:</label>
                        <input
                          type="number"
                          min={0}
                          value={editData?.igst || ""}
                          onChange={(e) => setEditData({ ...editData, igst: e.target.value })}
                        />
                      </div>
                    </div>
                    <div>
                      <label>Warranty:</label> <br />
                      {console.log("warranty_period", editData.warranty_period)
                      }
                      <input
                        type="text"
                        // placeholder="eg : 6 months 1 years , 5months , 3years"
                        value={editData?.warranty_period || ""}

                        onChange={(e) => setEditData({ ...editData, warranty_period: e.target.value })}
                      />
                    </div>
                  </form>
                  <button
                    type="button"
                    className="model-edit-popup-save"
                    onClick={()=>  handleSaveEdit (editData.price_id)}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}

          </div>
          {/* {console.log("userrolleee",userData.userrole)} */}
          <DataTable columns={columns} data={devices} fixedHeader pagination />

          {featureeditpopup && (
            <div className="feature-edit-popup-overlay">
              <div className="model-feature-edit-popup-content">
                <button className="raw-popup-close" onClick={() => setfeatureeditpopup(false)}>
                  <i className="fa-solid fa-x"></i>
                </button>
                <h3>Edit Features</h3>
                <div className="feature-edit-popup-input-main">
                  {modelRow.features.map((feature, index) => (
                    <div key={feature.feature_id || index}>
                      <label>{feature.feature}:</label>
                      <br />
                      <input
                        type="text"
                        value={feature.feature_value || ""}
                        onChange={(e) => handleFeatureChange(index, e.target.value)}
                      />
                    </div>
                  ))}
                </div>
                <button className="feature-save-btn" onClick={handleSaveFeature}>Save</button>
              </div>
            </div>
          )}

          {featuredeletepopup && (
            <div className="feature-edit-popup-overlay">
              <div className="model-feature-edit-popup-content">
                <button
                  className="model-popup-close"
                  onClick={() => setfeaturedeletepopup(false)}
                >
                  <i className="fa-solid fa-x"></i>
                </button>

                <div className="model-feature-delete-popup-list-main">
                  <ul>
                    {modelRow?.features?.map((feature, index) => {
                      console.log("Feature Object:", feature);
                      return (
                        <li key={feature.feature_id || index}>
                          {feature.feature} - {feature.feature_value || "N/A"}
                          <i
                            className="fa-solid fa-trash-can"
                            onClick={() => FeatureDelete(modelRow.price_id, feature.feature_id)}
                            style={{ cursor: "pointer", marginLeft: "10px", color: "red" }}
                          ></i>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          )}

          {featureaddpopup && (
            <div className="feature-edit-popup-overlay">
              <div className="model-feature-Add-popup-content">
                <button
                  className="model-popup-close"
                  onClick={() => setfeatureaddpopup(false)}
                >
                  <i className="fa-solid fa-x"></i>
                </button>
                <h3>Add Features</h3>
                <div className="model-feature-add-popup-input">
                  <input
                    type="text"
                    placeholder="Feature Name"
                    value={feature}
                    onChange={(e) => setFeature(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Feature Value"
                    value={featureValue}
                    onChange={(e) => setFeatureValue(e.target.value)}
                  />
                </div>
                <button className="add-save-btn" onClick={() => AddFeature(modelRow.price_id, feature, featureValue)}>
                  Add Feature
                </button>
              </div>
            </div>
          )}

          {/* 
          {featurepopup && (
            <div className="feature-popup-overlay">
              <div className="feature-popup-content-main">
                <button
                  className="model-popup-close"
                  onClick={() => setfeaturepopup(false)}
                >
                  <i className="fa-solid fa-x"></i>
                </button>
                <div className="feature-popup-content">
                  <div className="feature-popup-image-main">
                    <div className="feature-popup-image">
                      <img src={mainImage} alt="Main" />
                    </div>
                    <div className="feature-popup-subimg-main">
                      <img
                        src={model1}
                        alt="Sub 1"
                        onClick={() => handleImageClick(model1)}
                      />
                      <img
                        src={model3}
                        alt="Sub 2"
                        onClick={() => handleImageClick(model3)}
                      />
                      <img
                        src={model4}
                        alt="Sub 3"
                        onClick={() => handleImageClick(model4)}
                      />
                      <img
                        src={model2}
                        alt="Sub 4"
                        onClick={() => handleImageClick(model2)}
                      />
                    </div>
                  </div>
                  <div className="feature-popup-list-main">
                    <ul>
                      <li>Model Number: 5L1F-4M</li>
                      <li>Size: 86mm*86mm*36mm</li>
                      <li>Max: 10A</li>
                      <li>Max Voltage: AC 100~240V 50 Hz</li>
                      <li>Mechanical Life: 10 years</li>
                      <li>Color: White & Black</li>
                      <li>Standard: Universal</li>
                      <li>Material: Glass/PC</li>
                      <li>Working temperature: -20~60</li>
                      <li>Voltage range: AC 100~240V 50/60Hz</li>
                      <li>Self consuming power: 1mA</li>
                    </ul>
                  </div>
                </div>
                <div className="feature-popup-content-buttons">
                  <button
                    className="edit-image"
                    onClick={() => setEditImagePopup(true)}
                  >
                    Edit Image
                  </button>
                  <button className="edit-features">Edit Features</button>
                </div>
              </div>
            </div>
          )} */}
          {featurepopup && (
            <div className="feature-popup-overlay">
              <div className="feature-popup-content-main">
                <button className="model-popup-close" onClick={() => setfeaturepopup(false)}>
                  <i className="fa-solid fa-x"></i>
                </button>

                <div className="feature-popup-content">
                  {/* Feature Images */}
                  <div className="feature-popup-image-main">
                    <div className="feature-popup-image">
                      {mainImage ? (
                        <img src={mainImage} alt="Main" />
                      ) : (
                        <p>No Feature Images</p>
                      )}
                    </div>
                    <div className="feature-popup-subimg-main">
                      {featureImages.length > 0 ? (
                        featureImages.map((img, index) => (
                          <img
                            key={index}
                            src={`${backendurl}${img.images[0].image_url}`}
                            alt={`Feature ${index + 1}`}
                            onClick={() => handleImageClick(`${backendurl}${img.images[0].image_url}`)}
                            style={{
                              cursor: "pointer",
                              border: mainImage === `${backendurl}${img.images[0].image_url}` ? "1px solid var(--text-color)" : "none",
                            }}
                          />
                        ))
                      ) : (
                        <p>No Sub Images</p>
                      )}
                    </div>
                    <div className="Edit-Feature-Image">
                      <button className="" onClick={handleEditImagePopup}> Edit Feature Image </button>
                    </div>
                  </div>

                  {/* Web Images */}
                  <div className="feature-popup-web-image">
                    <div className="feature-popup-list-main">
                      {webImages.length > 0 ? (
                        webImages.map((img, index) => (
                          <img
                            key={index}
                            src={`${backendurl}${img.images[0].image_url}`}
                            alt={`Web ${index + 1}`}
                          />
                        ))
                      ) : (
                        <p>No Web Images</p>
                      )}

                    </div>
                    <div className="Edit-Feature-Image">
                      <button className="" onClick={handleEditFeaturePopup}>Edit Web Image</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          )}

          {/* Edit Image Popup */}
          {editImagePopup && (
            <div className="edit-image-popup-overlay">
              <div className="edit-image-popup-content">
                <button className="model-popup-close" onClick={() => setEditImagePopup(false)}>
                  <i className="fa-solid fa-x"></i>
                </button>

                <h3>Upload New Image</h3>

                <div className="model-edit-img-input-main">
                  {featureImages.map((img, index) => (
                    <div className="model-edit-img-input" key={index}>
                      <img src={`${backendurl}${img.images[0].image_url}`} alt={`Image ${index + 1}`} />
                      <div className="model-delete-img-btn" onClick={() => handleDeleteImage(img.images[0].image_id)}>
                        <i className="fa-solid fa-trash"></i>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="model-featur-edit-img-btn">
                  <label htmlFor="add-img"></label>
                  <input
                    type="file"
                    id="add-img"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                  <div className="model-change-img-btn" onClick={() => document.getElementById("add-img").click()}>
                    <p>Upload Photo</p>
                  </div>
                </div>

                <div className="edit-image-popup-buttons">
                  <button onClick={() => handleUploadimg(modelId)}>Save</button>

                </div>
              </div>
            </div>
          )}

          {/* Edit Feature Popup */}
          {editFeaturePopup && (
            <div className="edit-image-popup-overlay">
              <div className="edit-image-popup-content">
                <button
                  className="model-popup-close"
                  onClick={() => setEditFeaturePopup(false)}
                >
                  <i className="fa-solid fa-x"></i>
                </button>

                <h3>Upload New Feature</h3>

                <div className="model-edit-img-input-main">
                  {webImages.map((img, index) => (
                    <div className="model-edit-img-input" key={index}>
                      <img src={`${backendurl}${img.images[0].image_url}`} alt={`Image ${index + 1}`} />
                      <div className="model-delete-img-btn" onClick={() => handleDeletewebImage(img.images[0].image_id)}>
                        <i className="fa-solid fa-trash"></i>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="model-featur-edit-img-btn">
                  <label htmlFor="add-img"></label>
                  <input
                    type="file"
                    id="add-img"
                    style={{ display: "none" }}
                    accept="image/*"
                    multiple
                    onChange={handlewebFileChange}
                  />
                  <div className="model-change-img-btn" onClick={() => document.getElementById("add-img").click()}>
                    <p>Upload Photo</p>
                  </div>
                </div>

                <div className="edit-image-popup-buttons">
                  <button onClick={() => handleUploadwebimg (modelId)} >Save</button>
                </div>
              </div>
            </div>
          )}

          {deletePopup && (
            <div className="delete-popup-overlay">
              <div className="delete-popup-content">
                <p>Are you sure you want to delete this device?</p>
                <div className="delete-popup-btn">
                  <button className="delete-popup-confirm" onClick={handleDeleteConfirm}>
                    Confirm
                  </button>
                  <button className="delete-popup-cancel" onClick={handleCloseDeletePopup}>
                    Cancel
                  </button>
                </div>

              </div>
            </div>
          )}

          {addPopup && (
            <div className="model-popup-overlay">
              <div className="model-popup-content">
                <button className="model-popup-close" onClick={() => setAddPopup(false)}>
                  <i className="fa-solid fa-x"></i>
                </button>
                <h3>Add New Model</h3>
                <form>
                  <div className="model-add-input">
                    <label>Model :</label>
                    <input
                      type="text"
                      value={newModel.model}
                      onChange={(e) => setNewModel({ ...newModel, model: e.target.value })}
                    />
                  </div>

                  <div className="model-add-input">
                    <label>MRP :</label>
                    <input
                      type="number"
                      min={0}
                      value={newModel.mrp}
                      onChange={(e) => setNewModel({ ...newModel, mrp: e.target.value })}
                    />
                  </div>

                  <div className="model-add-input">
                    <label>Retail Price :</label>
                    <input
                      type="number"
                      min={0}
                      value={newModel.retail_price}
                      onChange={(e) => setNewModel({ ...newModel, retail_price: e.target.value })}
                    />
                  </div>
                  <div className="model-add-input-tax">
                    <div className="sgst tax">
                      <label>SGST:</label>
                      <input
                        type="number"
                        min={0}
                        value={newModel.sgst}
                        onChange={(e) => setNewModel({ ...newModel, sgst: e.target.value })}
                      />
                    </div>
                    <div className="cgst tax">
                      <label>CGST:</label>
                      <input
                        type="number"
                        min={0}
                        value={newModel.cgst}
                        onChange={(e) => setNewModel({ ...newModel, cgst: e.target.value })}
                      />
                    </div>
                    <div className="igst tax">
                      <label>IGST:</label>
                      <input
                        type="number"
                        min={0}
                        value={newModel.igst}
                        onChange={(e) => setNewModel({ ...newModel, igst: e.target.value })}
                      />
                    </div>
                  </div>



                  <div className="model-add-input">
                    <label>Discount :</label>
                    <input
                      type="number"
                      min={0}
                      value={newModel.discount}
                      onChange={(e) => setNewModel({ ...newModel, discount: e.target.value })}
                    />
                  </div>

                  <div className="model-add-input">
                    <label>Warranty Period :</label>
                    <input
                      type="text"
                      placeholder="e.g., 6 months, 1 year"
                      value={newModel.warranty_period}
                      onChange={(e) => setNewModel({ ...newModel, warranty_period: e.target.value })}
                    />
                  </div>

                </form>
                <button className="model-popup-save" onClick={handleAddNewModel}>
                  Save
                </button>
              </div>
            </div>
          )}


          {editPopup && (
            <div className="model-edit-popup-overlay">
              <div className="model-edit-popup-content">
                <button
                  className="model-edit-popup-close"
                  type="button"
                  onClick={() => setEditPopup(false)}
                >
                  <i className="fa-solid fa-x"></i>
                </button>
                <div className="model-raw-details-main">
                  <DataTable
                    columns={[
                      {
                        name: 'ID',
                        selector: (row, index) => index + 1, sortable: true
                      },
                      {
                        name: "Image",
                        selector: (row) => row.image,
                        cell: (row) => (
                          <img
                            src={row.image}
                            alt={row.name || "Raw Material"}
                            style={{
                              width: "40px",
                              height: "40px",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                          />
                        ),
                        sortable: false,
                      },
                      { name: "Component", selector: (row) => row.component, sortable: true },
                      { name: "Required Quantity", selector: (row) => row.required_qty, sortable: true },
                      {
                        name: "Action",
                        cell: (row) => (

                          <div className="device-action-btn">
                            <button className="action-button" onClick={() => {
                              // Assuming each raw material is part of the row object
                              const { model_id, id } = row; // Extract model_id and raw_material_id from the row
                              setEditData({ modelId: model_id, rawMaterialId: id }); // Set them in editData
                              setActionPopup(true); // Open the popup
                              { console.log(row) }
                            }}>
                              <i className="fa-solid fa-pen-to-square"></i>
                            </button>
                            <button className="action-button" onClick={() => handlerawDeleteClick(row)} >
                              <i className="fa-solid fa-trash-can"></i>
                            </button>
                          </div>
                        ),
                      },
                    ]}
                    data={rawMaterials}
                    fixedHeader
                  />
                </div>
                <div className="model-raw-add-main">
                  <div className="model-raw-add-head">
                    <p>Add Raw Material</p>
                  </div>
                  <div className="model-raw-inputs">
                    <select
                      className="model-popup-select"
                      value={selectedComponent}
                      onChange={(e) => setSelectedComponent(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Component
                      </option>
                      {components.map((data) => (
                        <option key={data.id} value={data.id}>
                          {data.category}
                          {console.log("mfv",data)}                          
                        </option>
                      ))}
                    </select>


                    <input
                      type="number"
                      min={0}
                      placeholder="Required Qty"
                      value={requiredQty}
                      onChange={(e) => setRequiredQty(e.target.value)}
                    />
                    <button onClick={() => handleAddRawMaterial(modelId)}>ADD</button>
                  </div>
                </div>

              </div>
            </div>
          )}
          {actionPopup && (
            <div className="action-popup-overlay">
              <div className="action-popup-content">
                <button
                  className="action-popup-close"
                  type="button"
                  onClick={handleCloseActionPopup}
                >
                  <i className="fa-solid fa-x"></i>
                </button>
                <div className="action-popup-input">
                  <label htmlFor="">Required Qty :</label>
                  <input
                    type="number"
                    min={0}
                    value={Qty} // Bind the input value to Qty state
                    onChange={(e) => setQty(e.target.value)} // Update the state when the input changes
                  />
                </div>
                <button
                  className="action-confirm-button"
                  onClick={() => handleSaveActionPopup()} // Call handleSaveActionPopup without arguments, it will use the current state
                >
                  Save
                </button>
              </div>
            </div>
          )}
          {showrawDeletePopup && (
            <div className="delete-raw-popup">
              <div className="delete-raw-content">
                <p>Are you sure you want to delete this raw material?</p>
                <p>{selectedRawMaterial?.component}</p>
                <div className="delete-popup-btn">
                  < button className="delete-popup-confirm" onClick={handlerawDeleteConfirm}>Confirm</button>
                  <button className="delete-popup-cancel" onClick={handleClosePopup}>Cancel</button>
                </div>

              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Devices;
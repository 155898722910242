import React, { useEffect, useState } from "react";
import "./Thing.css";
import { useTheme } from "../theme/ThemeContext";
import DataTable from "react-data-table-component";
import { FaLeaf } from "react-icons/fa";

const Thing = () => {
    const token = localStorage.getItem('authToken')
    console.log("token :", token)
    // const backendurl = process.env.REACT_APP_BACKEND_URL;
    const backendurl = window.location.hostname === 'localhost'
        ? ''
        : process.env.REACT_APP_BACKEND_URL;

    const { theme } = useTheme();
    const SecurityKeyCell = ({ securityKey }) => {
        const [isVisible, setIsVisible] = useState(false);
    
        return (
            <div
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
                style={{ cursor: "pointer" }}
            >
                {isVisible ? securityKey : "**********"}
            </div>
        );
    };
    const [showPopup, setShowPopup] = useState(false);
    const [editData, setEditData] = useState(null);
    const [showSlide, setShowSlide] = useState(false);
    const [thingsData, setThingsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [fetchUrl, setFetchUrl] = useState(`${backendurl}/dashboard/api/searchThings/working/instock/status/new`);
    const [searchTerm, setSearchTerm] = useState("");
    const [featurepopup, setfeaturepopup] = useState(false)
    const [mainImage, setMainImage] = useState()
    const [features, setFeatures] = useState([]);
    const [images, setImages] = useState([]);
    const [auditpopup, setAuditPopup] = useState(false)
    const [dataaa, setDataaa] = useState([]);
    const [columns,setColumns] = useState([]);
    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);
    const [devices, setDevices] = useState([]);
    const [selectedStock, setSelectedStock] = useState("In stock");
    const [selectedParty, setSelectedParty] = useState('');
    const [filterType, setFilterType] = useState("new");  // Default filter ty
    const [isStockDropdownVisible, setIsStockDropdownVisible] = useState(false);
    const [wifiData, setWifiData] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState("Customers");
    const [isCategoryDropdownVisible, setIsCategoryDropdownVisible] = useState(false);

    const getIconClass = (deviceType) => {
        const iconMap = {
            light: "fa-lightbulb",
            fan: "fa-fan",
            plug: "fa-plug"
        };
        return iconMap[deviceType] || "fa-question"; // Default icon if not found
    };

    const toggleStockDropdown = () => {
        setIsStockDropdownVisible(!isStockDropdownVisible);
    };

    const toggleCategoryDropdown = () => {
        setIsCategoryDropdownVisible(!isCategoryDropdownVisible);
    };



    const handleStockItemClick = (item) => {
        // Reset party and searchTerm when changing stock type
        // setSelectedParty('');
        // setSearchTerm('');
        setFetchUrl(`${backendurl}/dashboard/api/searchThings/working/${item}/status/new`);
        setSelectedStock(item);
        setIsStockDropdownVisible(false);

        // Show the second dropdown only if "Sold" is selected
        if (item === "sold") {
            setIsCategoryDropdownVisible(true);
        } else {
            setIsCategoryDropdownVisible(false);
        }
    };

    const handleCategoryItemClick = (item,name) => {
        setSelectedParty(item);
        setSelectedCategory(name);
        setIsCategoryDropdownVisible(false);
        handleFilterChange(filterType, item);

    };

    const handleImageClick = (image) => {
        setMainImage(image); // Update the main image
    };

    const handleAuditPopup = async (thing) => {
        setAuditPopup(true);
        console.log("thingIdddd:", thing);
        console.log("macaddress:", thing.macaddress);

        try {
            // Fetch device details first
            const deviceResponse = await fetch(`${backendurl}/api/devices/things/${thing.thing_id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!deviceResponse.ok) {
                throw new Error("Failed to fetch devices");
            }

            const deviceData = await deviceResponse.json();
            setDevices(deviceData); // Store device details

            // Fetch Wi-Fi data
            await fetchWifiData(thing.macaddress);  // Call fetchWifiData after device data is fetched

            // Fetch audit log data
            const auditLogResponse = await fetch(`${backendurl}/dashboard/api/display/auditlog/${thing.macaddress}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!auditLogResponse.ok) {
                throw new Error(`HTTP error! Status: ${auditLogResponse.status}`);
            }

            const auditLogData = await auditLogResponse.json();
            // console.log("Audit log data:", auditLogData);

            setDataaa(auditLogData.events); // Store audit log data

        } catch (error) {
            console.error("Error fetching data:", error);
            setError("Failed to load data");
        } finally {
            setLoading(false);
        }
    };

    let intervalId;

    const fetchWifiData = async (thingmac) => {
        console.log("macccccc", thingmac);
        try {
            const response = await fetch(`${backendurl}/dashboard/api/device/wifi/status/${thingmac}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch Wi-Fi data');
            }
            const data = await response.json();
            setWifiData(data.wifiData);
            console.log("wifiiiiii", data.wifiData);
    
        } catch (error) {
            console.error('Error fetching Wi-Fi data:', error);
        } finally {
            setLoading(false);  // Set loading to false after fetching
        }
    
        // Recursive call to fetch data every 30 seconds
        // intervalId = setTimeout(() => {
        //     fetchWifiData(thingmac);  // Recurse after 30 seconds
        //     console.log(`reload ${thingmac}`);
        // }, 1000);  // 30000ms = 30 seconds
    };
    
    // Call fetchWifiData to start the fetching process
    // Replace with actual `thingmac`
    
    // Set an interval to refetch every 30 seconds (30000 ms)
    // Dynamically fetch Wi-Fi data every 30 seconds



    // useEffect((macaddress) => {
    // const fetchData = async (macaddress) => {
    //     setLoading(true);
    //     setError(null);
    //     console.log("mac adressssss",macaddress);

    //     try {
    //         const response = await fetch(`${backendurl}/api/display/auditlog/${macaddress}?page=1&pageSize=10`, {
    //             headers: {
    //                 Authorization: `Bearer ${localStorage.getItem('authToken')}`,
    //             },
    //         });
    //         if (!response.ok) {
    //             throw new Error(`HTTP error! Status: ${response.status}`);
    //         }
    //         const result = await response.json();
    //         setDataaa(result.events);
    //     } catch (error) {
    //         console.error("Error fetching audit log:", error);
    //         setError("Failed to load audit log");
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // if (macaddress) {
    //     fetchData();
    // }
    // }, []);



    const handelFeaturepopup = (row) => {
        fetchModelData(row.model)
        setEditData(row);
        setfeaturepopup(true)
        // console.log("row id :", row)
    }

    const fetchModelData = async (modelId) => {
        console.log("alksncan", modelId);

        try {
            const response = await fetch(`${backendurl}/dashboard/api/things/features/${modelId}`);
            if (!response.ok) {
                throw new Error("Failed to fetch model data");
            }
            const data = await response.json();
            console.log(`data of features${data}`)
            console.log(`features${data.features}`)
            console.log(`image features${data.images}`)
            setFeatures(data.features || []);
            setImages(data.images || []);
            setMainImage(data.images?.[0] || null); // Set the first image as the main image
        } catch (error) {
            console.error("Error fetching model data:", error);
        }
    };


    const handleEdit = (row) => {
        setEditData(row);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setEditData(null);
    };

    const handleSlide = () => {
        setShowSlide((prev) => !prev);
    };

    useEffect(() => {
        fetchThingsData(fetchUrl);
    }, [fetchUrl]);

    const fetchThingsData = async (url) => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(url, {
                headers: {
                  'Authorization': ` Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              });
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
            const data = await response.json();
            console.log("Fetched data:", data);
            setThingsData(data);
            generateColumns(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // const handleFilterChange = (filterType) => {
    //     const newUrl = `${backendurl}/dashboard/api/searchThings/working/${filterType}${searchTerm ? `?serialno=${encodeURIComponent(searchTerm)}` : ""}`;
    //     setFetchUrl(newUrl);
    // };
    
    // const handleFilterChange = (filterType) => {
    //     const newUrl = `${backendurl}/dashboard/api/searchThings/working/${selectedStock}/status/${filterType}?party=customer`;
    //     console.log("qwertyuiop",newUrl)
    //     setFetchUrl(newUrl);
    // };

    // const handleSearchChange = (e) => {
    //     setSearchTerm(e.target.value);
    //     setError(null);
    //     const newUrl = `${backendurl}/dashboard/api/searchThings/working/${selectedStock}/status/${filterType}` ;
    //     setFetchUrl(newUrl);
    // };
        // Handle filter change (status)
        const handleFilterChange = (filterType,selectedParty) => {
            setFilterType(filterType);
            let newUrl = `${backendurl}/dashboard/api/searchThings/working/${selectedStock}/status/${filterType}`;
    
            // Add party parameter only if selectedStock is "sold"
            if (selectedStock === "sold" && selectedParty) {
                console.log("partyfilter::",selectedParty)
                newUrl += `?party=${selectedParty}`;
            }
    
            console.log("Filter Change URL:", newUrl);
            setFetchUrl(newUrl);
        };
     // Handle search input change
     const handleSearchChange = (e) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
        setError(null);

        let newUrl = `${backendurl}/dashboard/api/searchThings/working/${selectedStock}/status/${filterType}`;

        // Add party parameter only if selectedStock is "sold"
        if (selectedStock === "sold" && selectedParty) {
            console.log("party::",selectedParty)
            newUrl += `?party=${selectedParty}`;
        }

        // Add searchTerm if provided
        if (newSearchTerm) {
            newUrl += `&searchTerm=${encodeURIComponent(newSearchTerm)}`;
        }

        console.log("Search Change URL:", newUrl);
        setFetchUrl(newUrl);
    };
    // const handleCategoryItemClick = (partyType) => {
    //     setSelectedParty(partyType);
    // };

    // // Handle stock item click (for "sold" / "In Stock")
    // const handleStockItemClick = (stockType) => {
    //     setSelectedStock(stockType);
    //     // Reset party and searchTerm when changing stock type
    //     setSelectedParty('');
    //     setSearchTerm('');
    //     setFetchUrl(`${backendurl}/dashboard/api/searchThings/working/${stockType}/status/new`);
    // };

 
    

    const columns1 = [

        { name: "Switch", selector: (row) => row.switch || "null", sortable: true },
        { name: "State", selector: (row) => row.state || "null", sortable: true },
        { name: "Date", selector: (row) => row.date || "null", sortable: true },
        { name: "Time", selector: (row) => row.time || "null", sortable: true },
        { name: "Method", selector: (row) => row.method || "null", sortable: true },
        { name: "Type", selector: (row) => row.type || "null", sortable: true },
    ]
   
    const generateColumns = (data) => {
        let featureColumns = new Set();
        let attributeColumns = new Set();

        data.forEach((thing) => {
            if (thing.features) {
                thing.features.forEach((feature) => feature.feature && featureColumns.add(feature.feature));
            }
            if (thing.attributes) {
                thing.attributes.forEach((attribute) => attribute.attributeName && attributeColumns.add(attribute.attributeName));
            }
        });

    const columns = [
        {
            name: 'ID',
            selector: (row, index) => index + 1, sortable: true
        },
        { name: "Thing Name", selector: (row) => row.thingname || "null", sortable: true },
        { name: "Created By", selector: (row) => row.createdby || "null", sortable: true },
        { name: "Batch ID", selector: (row) => row.batchid || "null", sortable: true },
        { name: "Model", selector: (row) => row.model || "null", sortable: true },
        { name: "Serial No", selector: (row) => row.serialno || "null", sortable: true },
        {
            name: "Security Key",
            cell: (row) => <SecurityKeyCell securityKey={row.securitykey} />,
            sortable: true,
        },
        { name: "Status", selector: (row) => row.admin_stock_status || "null", sortable: true },
        // { name: "Added At", selector: (row) => row.added_date || "null", sortable: true },
        // // Extracted Date Only
        { 
            name: "Added Date", 
            selector: (row) => {
                const dateString = row.addedat ? row.addedat.split('T')[0] : "null";  // Safely check if added_date exists
                return dateString;
            },
            sortable: true 
        },
        { 
            name: "Added Time", 
            selector: (row) => {
                const timeString = row.addedat ? row.addedat.split('T')[1]?.split('.')[0] : "null";  // Safely check if added_date exists
                return timeString;
            },
            sortable: true 
        },
        
        
        
        
        
    
        { name: "Added By", selector: (row) => row.addedby || "null", sortable: true },
        { name: "user_name", selector: (row) => row.user_name || "null", sortable: true },
        { name: "user_phone", selector: (row) => row.user_phone|| "null", sortable: true },
        { name: "Failure Reason", selector: (row) => row.failurereason || "null", sortable: true },
        { name: "Fixed By", selector: (row) => row.fixed_by || "null", sortable: true },
        { name: "Logged At", selector: (row) => row.loggedat || "null", sortable: true },
       
    ];

    // Add dynamic feature columns
    featureColumns.forEach((feature) => {
        columns.push({
            name: feature,
            selector: (row) => {
                const featureObj = row.features?.find((f) => f.feature === feature);
                return featureObj ? featureObj.feature_value || "-" : "-";
            },
            sortable: true,
        });
    });
     // Add dynamic attribute columns
     attributeColumns.forEach((attribute) => {
        columns.push({
            name: attribute,
            selector: (row) => {
                const attributeObj = row.attributes?.find((a) => a.attributeName === attribute);
                return attributeObj ? attributeObj.attributeValue || "-" : "-";
            },
            sortable: true,
        });
    });
    const actionColumns = [
        // {
        //     name: "Features",
        //     cell: (row) => (
        //         <button className="test-button" onClick={() => handelFeaturepopup(row)}>
        //             <i className="fa-solid fa-gears"></i>
        //         </button>
        //     ),
        // },
        {
            name: "Audit Log",
            cell: (row) => (
                <button className="test-button" onClick={() => handleAuditPopup(row)}>
                    <i className="fa-regular fa-file"></i>
                </button>
            ),
        },
    ];
    
    const finalColumns=[...columns,...actionColumns]
    setColumns(finalColumns);

};
    return (
        <div className={`thing-main ${theme}-theme`}>
            <div className="dash-dummy"></div>
            <div className="second-main">
                <div className="thing-content-main">
                    <div className="thing-content">
                        <div className="thing-content-head">
                            <p>Thing Details</p>
                        </div>
                        <div className="thing-content-stock-deatails-main">
                            <div className="thing-content-stock-deatails">
                                <div
                                    className="thing-content-stock-product"
                                    onClick={toggleStockDropdown}
                                    style={{ cursor: "pointer" }}
                                >
                                    <p>{selectedStock}</p>
                                    <i className={`fa-solid ${isStockDropdownVisible ? "fa-angle-up" : "fa-angle-down"}`}></i>
                                </div>
                                {isStockDropdownVisible && (
                                    <div className="dropdown">
                                        <ul>
                                            <li onClick={() => handleStockItemClick("sold")}>Sold</li>
                                            <li onClick={() => handleStockItemClick("In Stock")}>In Stock</li>
                                            
                                        </ul>
                                    </div>
                                )}
                            </div>

                            {selectedStock === "sold" && (
                                <div className="thing-content-stock-deatails">
                                    <div
                                        className="thing-content-stock-product"
                                        onClick={toggleCategoryDropdown}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <p>{selectedCategory}</p>
                                        <i className={`fa-solid ${isCategoryDropdownVisible ? "fa-angle-up" : "fa-angle-down"}`}></i>
                                    </div>
                                    {isCategoryDropdownVisible && (
                                        <div className="dropdown">
                                            <ul>
                                                <li onClick={() => handleCategoryItemClick("customer","Customers")}>Customers</li>
                                                <li onClick={() => handleCategoryItemClick("onlineCustomer","Online Customers")}>Online Customers</li>
                                                <li onClick={() => handleCategoryItemClick("dealer","Dealers")}>Dealers</li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>


                        <div className="thing-content-items">
                            <div className="device-search">
                                <i className="fa-solid fa-magnifying-glass"></i>
                                <input
                                    type="search"
                                    placeholder="Search by Serial No..."
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </div>

                            <div style={{ position: "relative" }}>
                                <i className="fa-solid fa-sliders" onClick={handleSlide}></i>
                                {showSlide && (
                                    <div className="thing-slide-menu show">
                                        {["rework", "new", "returned", "exchange"].map(
                                            (filter) => (
                                                <div
                                                    key={filter}
                                                    className="thing-slide-item"
                                                    onClick={() => handleFilterChange(filter)}
                                                >
                                                    <span>{filter}</span>
                                                </div>
                                            )
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {loading ? (
                        <p>Loading...</p>
                    ) : error ? (
                        <p>Error: {error}</p>
                    ) : (
                        <DataTable columns={columns} data={thingsData} fixedHeader pagination />
                    )}

                    {showPopup && (
                        <div className="popup-overlay">
                            <div className="popup-content">
                                <button className="popup-close" onClick={handleClosePopup}>
                                    <i className="fa-solid fa-x"></i>
                                </button>

                                <button
                                    className="popup-save"
                                    onClick={() => {
                                        console.log("Updated Data: ", editData);
                                        handleClosePopup();
                                    }}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    )}


                    {featurepopup && (
                        <div className="feature-popup-overlay">
                            <div className="feature-popup-content-main">
                                <button
                                    className="model-popup-close"
                                    onClick={() => setfeaturepopup(false)}
                                >
                                    <i className="fa-solid fa-x"></i>
                                </button>
                                <div className="feature-popup-content">
                                    <div className="feature-popup-image-main">
                                        <div className="feature-popup-image">
                                            <img src={`${backendurl}${mainImage}`} alt="Main" />
                                        </div>
                                        <div className="feature-popup-subimg-main">
                                            {images.map((img, index) => (
                                                <img
                                                    key={index}
                                                    src={`${backendurl}${img}`}
                                                    alt={`Sub ${index + 1}`}
                                                    onClick={() => handleImageClick(img)}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <div className="feature-popup-list-main">
                                        <ul>
                                            {features.map((feature, index) => (
                                                <li key={index}>{feature}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                     
                            </div>
                        </div>
                    )}

                    {auditpopup && (
                        <div className="audit-popup-overlay">
                            <div className="audit-popup-content-main">
                                <button className="model-popup-close" onClick={() => setAuditPopup(false)}>
                                    <i className="fa-solid fa-x"></i>
                                </button>
                                <div className="audit-popup-content">
                                    <div className="audit-popup-btn-details">
                                        <div className="audit-popup-btn-main">
                                            {devices.map((device, index) => {
                                                const iconClass = getIconClass(device.icon);
                                                {
                                                    console.log("iconnnn", device.icon);
                                                }
                                                return (
                                                    <button key={index}>
                                                        <i className={`fa-solid ${iconClass}`}></i>
                                                    </button>
                                                );
                                            })}
                                        </div>
                                        {loading ? (
                                            <p>Loading Wi-Fi data...</p>
                                        ) : (
                                            <div className="audit-popup-wifi-main">
                                                <div className="audit-popup-wifi-details">
                                                    <p>Connection:</p>
                                                    <p>{wifiData ? wifiData.connection : 'N/A'}</p>

                                                </div>
                                                <div className="audit-popup-wifi-details">
                                                    <p>Strength:</p>
                                                    <p>{wifiData ? wifiData.signalStrength : 'N/A'} , {wifiData ? wifiData.quality : 'N/A'}</p>
                                                </div>
                                                <div className="audit-popup-wifi-details">
                                                    <p>Wifi SSID:</p>
                                                    <p>{wifiData ? wifiData.wifiSSID : 'N/A'}</p>
                                                </div>
                                                <div className="audit-popup-wifi-details">
                                                    <p>Device Type:</p>
                                                    <p>{wifiData ? wifiData.deviceType : 'N/A'}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="audit-popup-state-details">
                                        <div className="audit-popup-state-main">
                                            <DataTable columns={columns1} data={dataaa} fixedHeader pagination />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Thing;
